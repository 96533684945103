import cuid2 from '@paralleldrive/cuid2';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CreateAppointmentDTO } from '@aster/app/core/shared/dtos/appointment';

import {
  createMutationScopeID,
  MutationScope,
} from '../../../../../mutations/utils/create-mutation-scope-id';

import { useSnackbar } from '../../../../../components/Snack';

import axios from '../../../../../app/axiosConfig';
import { logAnalyticEvent } from '../../../../../app/firebase';

const createAppointmentInner = async (appointment: CreateAppointmentDTO) => {
  return await axios.post('/appointments', appointment);
};

export const useCreateAppointmentMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const queryClient = useQueryClient();

  const { showMessage } = useSnackbar();

  const {
    mutate: createAppointment,
    isPending: isCreatingAppointment,
    ...rest
  } = useMutation({
    mutationKey: ['createAppointment'],
    mutationFn: createAppointmentInner,
    scope: {
      id: createMutationScopeID(MutationScope.APPOINTMENT),
    },
    onMutate: (
      appointment: CreateAppointmentDTO & { id?: string; loading?: boolean }
    ) => {
      const [patientID] = appointment.invitedPatientIDs;

      appointment.id = cuid2.createId();
      appointment.loading = true;

      const previousAppointments = queryClient.getQueryData([
        'appointmentsByPatient',
        patientID,
      ]);

      queryClient.setQueryData<CreateAppointmentDTO[]>(
        ['appointmentsByPatient', patientID],
        (old = []) => [...old, appointment]
      );

      return { previousAppointments };
    },
    onError: (_, appointment, context) => {
      const [patientID] = appointment.invitedPatientIDs;

      queryClient.setQueryData(
        ['appointmentsByPatient', patientID],
        context?.previousAppointments
      );

      showMessage({
        message: 'Error creating appointment',
        type: 'error',
      });

      onError?.();
    },
    onSuccess: (_, variables) => {
      const [patientID] = variables.invitedPatientIDs;

      void queryClient.invalidateQueries({
        queryKey: ['appointmentsByPatient', patientID],
      });

      logAnalyticEvent('calendar', 'create_appt');
      showMessage({
        message: 'Appointment created',
        type: 'success',
      });

      onSuccess?.();
    },
  });

  return { createAppointment, isCreatingAppointment, ...rest };
};
