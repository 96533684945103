import { keepPreviousData, useQuery } from '@tanstack/react-query';

import {
  GetLedgerRecordsInput,
  LedgerRecordDTO,
} from '@aster/app/core/shared/dtos/billing';

import axios from '../../../app/axiosConfig';

const getRecords = async ({ patientID }: GetLedgerRecordsInput) => {
  const { data: records } = await axios.get<LedgerRecordDTO[]>(
    '/v2/billing' + (patientID ? `/by-patient/${patientID}` : '')
  );

  return records.map((record) => ({
    ...record,
    amount: Number(record.amount),
    adjustment: Number(record.adjustment),
  }));
};

export const useLedgerRecordsQuery = ({
  patientID,
}: GetLedgerRecordsInput = {}) => {
  const { data: ledgerRecords, ...rest } = useQuery({
    queryKey: ['ledger', ...(patientID ? [patientID] : [])],
    queryFn: () => getRecords({ patientID }),
    placeholderData: keepPreviousData,
  });

  return { ledgerRecords, ...rest };
};
