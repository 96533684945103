import { StateCreator } from 'zustand';
import { LabsRootSlice } from './root-labs.slice';
import { TryVitalResult } from '@aster/app/core/shared/dtos/labs';
import { createId } from '@paralleldrive/cuid2';

type ManuallyUploadLabsOrderState = {
  manualUploadResultsDialogState: 'upload' | 'fill' | 'closed';
  processedResults: TryVitalResult | null;
  selectedFile: File | null;
  generatedLabOrderID: string | null;
};

const defaultState: ManuallyUploadLabsOrderState = {
  manualUploadResultsDialogState: 'closed',
  processedResults: null,
  selectedFile: null,
  generatedLabOrderID: null,
};

type ManuallyUploadLabsOrderAction = {
  openManualUploadResultsDialog: () => void;
  closeManualUploadResultsDialog: () => void;
  goToFillResults: () => void;
  goToUploadResults: () => void;
  setProcessedResults: (results: TryVitalResult | null) => void;
  setSelectedFile: (file: File | null) => void;
};
export type ManuallyUploadLabsSlice = ManuallyUploadLabsOrderState &
  ManuallyUploadLabsOrderAction;

export const createManuallyUploadLabsSlice: StateCreator<
  ManuallyUploadLabsSlice & LabsRootSlice,
  [],
  [],
  ManuallyUploadLabsSlice
> = (set) => ({
  // State
  ...defaultState,
  // Actions
  openManualUploadResultsDialog: () => {
    set({
      manualUploadResultsDialogState: 'upload',
      isCreateOrderDialogOpened: false,
      selectedLabOrderID: null,
      generatedLabOrderID: createId(),
    });
  },
  closeManualUploadResultsDialog: () => {
    set({
      ...defaultState,
    });
  },
  setProcessedResults: (results) => {
    set({ processedResults: results });
  },
  goToFillResults: () => {
    set({ manualUploadResultsDialogState: 'fill' });
  },
  setSelectedFile: (file) => {
    set({ selectedFile: file });
  },
  goToUploadResults: () => {
    set({
      manualUploadResultsDialogState: 'upload',
      selectedFile: null,
      processedResults: null,
    });
  },
});
