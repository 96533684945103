import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import axios from '../../app/axiosConfig';
import {
  CreateDosespotClinicDTO,
  CreateDosespotClinicianDTO,
  CreateDosespotPatientDTO,
  DoseSpotNotificationDTO,
} from '@aster/app/core/shared/dtos/dosespot';
import { useQueryStaffByEmail } from '../../queries/query-staff';
import { AxiosResponse } from 'axios';
import { useAuth } from '../../authentication/AuthProvider';
import { StaffListItemDTO } from '@aster/app/core/shared/dtos/staff';
import { DosespotContextType, DosespotDataType } from './types';

const defaultDosespotState: DosespotContextType = {
  iframe: '',
  loading: false,
  dosespotData: {
    client_id: null,
    client_secret: null,
    username: null,
    password: null,
    notificationsCount: 0,
    clinicianConfirmed: false,
  },
  getDosespotJwt: async () => {},
  getDosespotNotificationsCount: async () => {},
  createDosespotClinic: async () => {
    return {} as AxiosResponse<any, any>;
  },
  createDosespotClinician: async () => {
    return {} as AxiosResponse<any, any>;
  },
  getDosespotClinician: async () => {
    return {} as AxiosResponse<any, any>;
  },
  createDosespotPatient: async () => {
    return {} as AxiosResponse<any, any>;
  },
  getDosespotSSO: async () => {
    return '';
  },
};

const DosespotContext =
  createContext<DosespotContextType>(defaultDosespotState);

const DosespotProvider = ({ children }: { children: ReactNode }) => {
  const [currentDosespotData, setCurrentDosespotData] = useState<
    DosespotContextType['dosespotData']
  >({
    client_id: '',
    client_secret: '',
    username: '',
    password: '',
    notificationsCount: 0,
    clinicianConfirmed: false,
  });

  const [loading, setLoading] = useState(false);
  const [iframe, setIframe] = useState('');

  const { profile, setSessionStaff } = useAuth();
  const { staff, areStaffLoading } = useQueryStaffByEmail(
    (profile && profile.email) || ''
  );

  useEffect(() => {
    const loadDosespot = async (staff: StaffListItemDTO) => {
      setLoading(true);
      setCurrentDosespotData((prev) => {
        return {
          ...prev,
          client_id: staff.dosespotClinicId,
          client_secret: staff.dosespotClinicKey,
          username: staff.dosespotClinicianId,
          password: staff.dosespotClinicKey,
        };
      });
      setSessionStaff(JSON.stringify(staff));

      if (
        staff.dosespotClinicId &&
        staff.dosespotClinicKey &&
        staff.dosespotClinicianId
      ) {
        await getDosespotJwt({
          client_id: staff.dosespotClinicId,
          client_secret: staff.dosespotClinicKey,
          username: import.meta.env.VITE_DOSESPOT_ADMIN_USER_ID,
          password: staff.dosespotClinicKey,
        });

        const dosespotClinician = await getDosespotClinician(
          staff.dosespotClinicianId
        );
        if (dosespotClinician.data) {
          setCurrentDosespotData((prev) => {
            return {
              ...prev,
              clinicianConfirmed: dosespotClinician.data.Item.Confirmed,
            };
          });
        } else {
          setLoading(false);
          return;
        }

        if (dosespotClinician.data.Item.Confirmed) {
          const iframe = await getDosespotSSO({
            client_id: staff.dosespotClinicId,
            client_secret: staff.dosespotClinicKey,
            username: staff.dosespotClinicianId,
            password: staff.dosespotClinicKey,
          });

          if (iframe) {
            setIframe(iframe);
          }
          await getDosespotNotificationsCount();
        }
      }
      setLoading(false);
    };

    if (staff) {
      void loadDosespot(staff);
    }
  }, [staff]);

  const setDosespotClinic = (
    client_id: string,
    client_secret: string,
    password: string
  ) => {
    setCurrentDosespotData((prev) => {
      return {
        ...prev,
        client_id,
        client_secret,
        password,
      };
    });
  };

  const setDosespotClinician = (clinicianId: string) => {
    setCurrentDosespotData((prev) => {
      return {
        ...prev,
        username: clinicianId,
      };
    });
  };

  const getDosespotJwt = async ({
    client_id,
    client_secret,
    username,
    password,
  }: {
    client_id: string;
    client_secret: string;
    username: string;
    password: string;
  }) => {
    const response = await axios.post('dosespot/jwt', {
      client_id,
      client_secret,
      username,
      password,
    });
    axios.defaults.headers.common['dosespotJWT'] = response.data.access_token;
    return response;
  };

  const getDosespotNotificationsCount = async () => {
    const response = await axios.get('dosespot/notifications');
    const notifications = response.data as DoseSpotNotificationDTO;
    if (notifications) {
      const count =
        notifications.PendingPrescriptionsCount +
        notifications.PendingRxChangeCount +
        notifications.RefillRequestsCount +
        notifications.TransactionErrorsCount;
      setCurrentDosespotData((prev) => {
        return {
          ...prev,
          notificationsCount: count,
        };
      });
      return;
    }
    setCurrentDosespotData((prev) => {
      return {
        ...prev,
        notificationsCount: 0,
      };
    });
  };

  const createDosespotClinic = async ({
    ClinicName,
    Address1,
    City,
    State,
    ZipCode,
    PrimaryPhone,
  }: CreateDosespotClinicDTO) => {
    await getDosespotJwt({
      client_id: import.meta.env.VITE_DOSESPOT_CLINIC_ID,
      client_secret: import.meta.env.VITE_DOSESPOT_CLINIC_KEY,
      username: import.meta.env.VITE_DOSESPOT_ADMIN_USER_ID,
      password: import.meta.env.VITE_DOSESPOT_CLINIC_KEY,
    });
    const response = await axios.post('dosespot/clinics', {
      ClinicName,
      Address1,
      City,
      State,
      ZipCode,
      PrimaryPhone,
    });
    if (response.status === 200 && response.data.ClinicId) {
      setDosespotClinic(
        String(response.data.ClinicId),
        response.data.ClinicKey,
        response.data.ClinicKey
      );
    }
    return response;
  };

  const createDosespotClinician = async ({
    FirstName,
    LastName,
    DateOfBirth,
    Address1,
    City,
    State,
    ZipCode,
    PrimaryPhone,
    PrimaryFax,
    DEANumber,
    NPINumber,
    MedicalLicenseNumbers,
  }: CreateDosespotClinicianDTO) => {
    const response = await axios.post('dosespot/clinicians', {
      FirstName,
      LastName,
      DateOfBirth,
      Address1,
      City,
      State,
      ZipCode,
      PrimaryPhone,
      PrimaryFax,
      DEANumber,
      NPINumber,
      MedicalLicenseNumbers,
    });
    if (response.status === 200 && response.data.Id) {
      setDosespotClinician(String(response.data.Id));
    }
    return response;
  };

  const getDosespotClinician = async (
    username: DosespotDataType['username']
  ) => {
    const response = await axios.get(`dosespot/clinician/${username}`);
    return response;
  };

  const createDosespotPatient = async ({
    patientId,
    FirstName,
    LastName,
    DateOfBirth,
    Address1,
    Gender,
    City,
    State,
    ZipCode,
    PrimaryPhone,
    PrimaryPhoneType,
    Active,
  }: CreateDosespotPatientDTO) => {
    const { client_id, client_secret, username, password } =
      currentDosespotData;
    if (!client_id || !client_secret || !username || !password) {
      return;
    }

    await getDosespotJwt({
      client_id,
      client_secret,
      username,
      password,
    });

    const response = await axios.post('dosespot/patients', {
      patientId,
      FirstName,
      LastName,
      DateOfBirth,
      Address1,
      Gender,
      City,
      State,
      ZipCode,
      PrimaryPhone,
      PrimaryPhoneType,
      Active,
    });
    return response;
  };

  const getDosespotSSO = async (
    dosespotData: Omit<
      DosespotDataType,
      'notificationsCount' | 'clinicianConfirmed'
    >,
    patientId?: string
  ) => {
    if (
      dosespotData.client_id &&
      dosespotData.client_secret &&
      dosespotData.username
    ) {
      setLoading(true);
      await getDosespotJwt({
        client_id: dosespotData.client_id,
        client_secret: dosespotData.client_secret,
        username: import.meta.env.VITE_DOSESPOT_ADMIN_USER_ID,
        password: dosespotData.client_secret,
      });

      const dosespotClinician = await getDosespotClinician(
        dosespotData.username
      );

      if (dosespotClinician.data) {
        setCurrentDosespotData((prev) => {
          return {
            ...prev,
            clinicianConfirmed: dosespotClinician.data.Item.Confirmed,
          };
        });

        const response = await axios.post('dosespot/sso', {
          client_id: dosespotData.client_id,
          patient_id: patientId,
          client_secret: dosespotData.client_secret,
          username: dosespotData.username,
        });
        setIframe(response.data as string);
        setLoading(false);
        return response.data as string;
      }
    }
    setLoading(false);
    return '';
  };

  const dosespotContext = useMemo(
    () => ({
      iframe: iframe,
      loading: loading || areStaffLoading,
      dosespotData: currentDosespotData,
      getDosespotJwt,
      getDosespotNotificationsCount,
      createDosespotClinic,
      createDosespotClinician,
      getDosespotClinician,
      createDosespotPatient,
      getDosespotSSO,
    }),
    [currentDosespotData, loading]
  );

  return (
    <DosespotContext.Provider value={dosespotContext}>
      {children}
    </DosespotContext.Provider>
  );
};

function useDosespot() {
  const dosespotContext = useContext(DosespotContext);
  if (!dosespotContext) {
    throw new Error('useDosespot must be used within a DosespotProvider');
  }
  return dosespotContext;
}

export { DosespotProvider, useDosespot };
