import dayjs from 'dayjs';

import { useQuery } from '@tanstack/react-query';

import { EncounterDTO, EncounterType } from '@aster/app/core/shared/dtos/encounter';
import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import { VitalsDTO } from '@aster/app/core/shared/dtos/vitals';

import { FlowsheetRow } from '../components/Flowsheet';

import axios from '../../../../../app/axiosConfig';

const parseGestationalAge = (age: string) => {
  const [weeks, days] = age
    .replace(/[d|w]/gi, '')
    .split(' ')
    .map((value) => parseInt(value));

  return { weeks, days };
};

const getFlowsheet = async (patient: PatientProfileForEncounterDTO) => {
  const [encountersResponse, vitalsResponse] = await Promise.all([
    axios.get<EncounterDTO[]>(`encounters/byPatient/${patient.patientID}`),
    axios.get<VitalsDTO[]>(`vitals/byPatient/${patient.patientID}`),
  ]);

  const encounters = encountersResponse?.data ?? [];
  const vitals = vitalsResponse?.data ?? [];

  const { weeks, days } = parseGestationalAge(
    patient.gestationalAge ?? '0w 0d'
  );

  const pregnancyNow = dayjs();
  const pregnancyStart = pregnancyNow
    .subtract(weeks, 'weeks')
    .subtract(days, 'days');

  const flowsheet = encounters
    .filter((encounter) => encounter.templateType === EncounterType.prenatal)
    .sort(
      (a, b) =>
        new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
    )
    .map((encounter) => {
      const encounterContent = encounter.content;
      const encounterVitals = vitals
        .filter((vital) => vital.encounterID === encounter.id)
        .sort(
          (a, b) =>
            new Date(b.timeCreated).getTime() -
            new Date(a.timeCreated).getTime()
        )[0];

      const daysSincePregnancyStart = Math.abs(
        dayjs(encounter.startTime).diff(pregnancyStart, 'days')
      );

      const gestationalWeeksThen = Math.floor(daysSincePregnancyStart / 7);
      const gestationalDaysThen = daysSincePregnancyStart % 7;

      return {
        weeks: `${gestationalWeeksThen}w${gestationalDaysThen}d`,
        date: new Date(encounter.startTime),
        bp:
          encounterVitals?.systolic && encounterVitals?.diastolic
            ? `${encounterVitals?.systolic ?? 0}/${
                encounterVitals?.diastolic ?? 0
              }`
            : '',
        pulse: encounterVitals?.heartRate ?? '',
        fh: encounterContent?.prenatalExamination?.fundalHeight
          ? `${encounterContent?.prenatalExamination?.fundalHeight}cm`
          : '',
        fht: encounterContent?.prenatalExamination?.fetalHeartRate ?? '',
        fetalPosition:
          encounterContent?.prenatalExamination?.fetalPosition ?? '',
        fm: encounterContent?.prenatalExamination?.fetalMovement ?? false,
        notes: encounterContent?.notes ?? '',
        link: `/encounter/${patient.patientID}/${encounter.id}`,
      } as FlowsheetRow;
    });

  return flowsheet;
};

export const useFlowsheetQuery = (
  patient: PatientProfileForEncounterDTO,
  opts = {}
) => {
  const {
    data: flowsheet,
    isLoading: isFlowsheetLoading,
    ...rest
  } = useQuery({
    queryKey: ['flowsheet', patient?.patientID],
    queryFn: () => getFlowsheet(patient),
    enabled: !!patient?.patientID,
    ...opts,
  });

  return { flowsheet, isFlowsheetLoading, ...rest };
};
