import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import ButtonType from '../../components/Button';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import BasicTextfield from '../../components/Textfield';
import { z } from 'zod';
import Typography from '../../components/Typography';
import CircleInfo from '../../assets/icons/CircleInfo';
import { useEFaxSetup } from './EFaxSetupContext';
import PlanSummary from './PlanSummary';

const SetUpFaxModalInitialStep = () => {
  const {
    goToConfirmationStep,
    isEFaxSetupOpen,
    cancelSetup,
    eFaxSetupStep,
    areaCode: predefinedAreaCodeCode,
  } = useEFaxSetup();

  const form = useForm({
    defaultValues: {
      areaCode: predefinedAreaCodeCode ?? '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      goToConfirmationStep(value.areaCode);
    },
  });

  return (
    <Dialog
      open={isEFaxSetupOpen && eFaxSetupStep === 'initial'}
      onClose={cancelSetup}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h4">Add a fax line</Typography>
      </DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <DialogContent>
          <div className="flex flex-col space-y-4">
            <div className="bg-pastelOrange bg-opacity-25 rounded-xl p-3 mb-2 flex items-center gap-3">
              <CircleInfo height="auto" width={39} />
              <Typography variant="bodyMedium">
                To add a number, choose the location of your new number using
                the options below. After you confirm your selection, your new
                eFax number will be placed on your account.
              </Typography>
            </div>
            <div className="bg-grayCard p-5 pb-6 rounded-[20px]">
              <form.Field
                name="areaCode"
                validators={{
                  onBlur: z
                    .string()
                    .length(3, 'The area code must be 3 digits long')
                    .refine(
                      (value) => Number.isFinite(Number(value)),
                      'The area code must be a number'
                    ),
                }}
                children={(field) => (
                  <BasicTextfield
                    id={field.name}
                    variant="filled"
                    label="Area code"
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    inputProps={{
                      maxLength: 3,
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    error={field.state.meta.errors?.length > 0}
                    helperText={field.state.meta.errors?.join('\r')}
                    width="100%"
                  />
                )}
              ></form.Field>
            </div>
            <PlanSummary />
          </div>
        </DialogContent>
        <DialogActions className=" bg-white flex flex-col items-center">
          <form.Subscribe
            selector={(state) => [
              state.canSubmit,
              state.isSubmitting,
              state.isFieldsValidating,
            ]}
            children={([canSubmit, isSubmitting, isValidating]) => (
              <ButtonType
                variant="contained"
                type="submit"
                text="Continue"
                disabled={!canSubmit}
                loading={isSubmitting || isValidating}
                classes="min-h-[42px] w-full"
              />
            )}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SetUpFaxModalInitialStep;
