import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

import { faCalendar, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventImpl } from '@fullcalendar/core/internal';
import { CircularProgress } from '@mui/material';

import {
  AppointmentWithInviteesDTO,
  CreateAppointmentDTO,
  UpdateAppointmentDTO,
} from '@aster/app/core/shared/dtos/appointment';
import { CreateEncounterDTO, TemplateType } from '@aster/app/core/shared/dtos/encounter';
import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';

import { AppointmentCard } from '../components/AppointmentCard';
import { EmptyState } from '../components/EmptyState';
import { ExpandableGrid } from '../components/ExpandableGrid';
import { SectionTitle } from '../components/SectionTitle';
import { useCreateAppointmentMutation } from '../mutations/use-create-appointment-mutation';
import { useCreateEncounterMutation } from '../mutations/use-create-encounter-mutation';
import { useDeleteAppointmentMutation } from '../mutations/use-delete-appointment-mutation';
import { useEditAppointmentMutation } from '../mutations/use-edit-appointment-mutation';
import { useAppointmentsByPatientQuery } from '../queries/use-appointments-query';

import { isPatientReadonly } from '../../../utils/is-patient-readonly';

import { logAnalyticEvent } from '../../../../../app/firebase';
import ButtonType from '../../../../../components/Button';
import ConfirmationModal from '../../../../../components/ConfirmationModal';

import { useStaffColorMap } from '../../../../../features/calendar/hooks/use-staff-color-map';
import CreateApptModal from '../../../../../features/calendar/modals/CreateApptModal';
import EditApptModal from '../../../../../features/calendar/modals/EditApptModal';
import { NewEvent } from '../../../../../features/calendar/types';
import { appointmentToEventSource } from '../../../../../features/calendar/utils/appointment-to-event-source';
import { getAppointmentType } from '../../../../../features/calendar/utils/get-appointment-type';

const APPOINTMENT_TIME_FRAME = 15;

const prepareEventForCreation = () => {
  // Appointments are created by default with 1-hour duration,
  // starting from the next available 15 minute hour timeslot.
  const now = dayjs();

  const timeUntilNext15MinSlot =
    APPOINTMENT_TIME_FRAME - (now.minute() % APPOINTMENT_TIME_FRAME);

  const start = now.add(timeUntilNext15MinSlot, 'minutes');
  const end = start.add(1, 'hour');

  return { start, end } as NewEvent;
};

const prepareAppointmentForMutation = <T,>(
  evt: Partial<EventImpl> & Record<string, any>
): T => {
  const typeDisplayName = evt.type || evt.dispAppt;
  const type = getAppointmentType(typeDisplayName);
  return {
    id: evt.apptId,
    loading: true,
    invitedPatientIDs: evt.invitedPatientIDs ?? [],
    invitedStaffIDs: evt.invitedStaffIDs ?? [],
    type: type,
    startTime: (evt.start as Date).toISOString(),
    endTime: (evt.end as Date)?.toISOString(),
    telehealth: false,
    note: evt.note,
  } as T;
};

export const AppointmentsSection = ({
  patient,
}: {
  patient: PatientProfileForEncounterDTO;
}) => {
  const { staffColorMap } = useStaffColorMap();

  const { appointments = [], areAppointmentsLoading } =
    useAppointmentsByPatientQuery(patient);

  const { createAppointment } = useCreateAppointmentMutation();
  const { editAppointment } = useEditAppointmentMutation();
  const { deleteAppointment } = useDeleteAppointmentMutation();

  const { createEncounter } = useCreateEncounterMutation();

  const [newEvent, setNewEvent] = useState<NewEvent | null>(null);

  const [isCreateAppointmentModalOpen, setIsCreateAppointmentModalOpen] =
    useState(false);

  const [selectedAppointmentIdForEdition, setSelectedAppointmentIdForEdition] =
    useState<string | null>(null);
  const [
    selectedAppointmentIdForDeletion,
    setSelectedAppointmentIdForDeletion,
  ] = useState<string | null>(null);

  const selectedAppointmentForEdition = useMemo(
    () =>
      appointments.find(
        (appointment) => appointment.id === selectedAppointmentIdForEdition
      ),
    [appointments, selectedAppointmentIdForEdition]
  );
  const selectedAppointmentForDeletion = useMemo(
    () =>
      appointments.find(
        (appointment) => appointment.id === selectedAppointmentIdForDeletion
      ),
    [appointments, selectedAppointmentIdForDeletion]
  );

  return (
    <>
      <div className="space-y-4">
        <SectionTitle
          icon={<FontAwesomeIcon icon={faCalendar} />}
          action={
            <button
              className="text-aster-main font-semibold content-center disabled:opacity-50 disabled:grayscale"
              onClick={() => {
                const event = prepareEventForCreation();

                setNewEvent(event);
                setIsCreateAppointmentModalOpen(true);
              }}
              disabled={isPatientReadonly(patient)}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" /> New Appointment
            </button>
          }
        >
          Upcoming Appointments
        </SectionTitle>
        {areAppointmentsLoading ? (
          <div className="text-center">
            <CircularProgress />
          </div>
        ) : appointments.length ? (
          <ExpandableGrid cols={3} limit={3} buttonText="See All Appointments">
            {appointments.map(
              (
                appointment: AppointmentWithInviteesDTO & {
                  loading?: boolean;
                }
              ) => (
                <AppointmentCard
                  id={appointment.id}
                  type={appointment.type}
                  startTime={appointment.startTime}
                  endTime={appointment.endTime}
                  invitedStaff={appointment.invitedStaff}
                  patientID={patient.patientID}
                  encounterID={appointment.encounterID}
                  onEditClick={(id) => {
                    setSelectedAppointmentIdForEdition(id);
                  }}
                  onDeleteClick={(id) => {
                    setSelectedAppointmentIdForDeletion(id);
                  }}
                  onCreateEncounterClick={(id, type) => {
                    const encounter: Partial<CreateEncounterDTO> = {
                      patientID: patient.patientID,
                      appointmentID: id,
                      templateType: type as TemplateType,
                      startTime: appointment.startTime,
                    };

                    createEncounter(encounter);
                  }}
                  loading={appointment.loading}
                  disabled={isPatientReadonly(patient)}
                />
              )
            )}
          </ExpandableGrid>
        ) : (
          <EmptyState
            icon={<FontAwesomeIcon icon={faCalendar} />}
            title="No upcoming appointments"
            description={`Get started by creating an appointment for ${
              patient.name.split(' ')?.[0]
            }`}
            action={
              <ButtonType
                variant="contained"
                text="New appointment"
                onClick={() => {
                  const event = prepareEventForCreation();

                  setNewEvent(event);
                  setIsCreateAppointmentModalOpen(true);
                }}
                disabled={isPatientReadonly(patient)}
              />
            }
          />
        )}
      </div>
      {isCreateAppointmentModalOpen && newEvent && (
        <CreateApptModal
          staffColorMap={staffColorMap}
          open={isCreateAppointmentModalOpen}
          eventInfo={newEvent}
          handleConfirm={(evt) => {
            const appointment =
              prepareAppointmentForMutation<CreateAppointmentDTO>(evt);

            createAppointment(appointment);

            setNewEvent(null);
            setIsCreateAppointmentModalOpen(false);
          }}
          handleClose={() => {
            setNewEvent(null);
            setIsCreateAppointmentModalOpen(false);
          }}
        />
      )}
      {selectedAppointmentIdForEdition && selectedAppointmentForEdition && (
        <EditApptModal
          staffColorMap={staffColorMap}
          open={Boolean(selectedAppointmentIdForEdition)}
          eventInfo={appointmentToEventSource(selectedAppointmentForEdition)}
          handleConfirm={(evt) => {
            const appointment =
              prepareAppointmentForMutation<UpdateAppointmentDTO>(evt);

            editAppointment(appointment);

            setSelectedAppointmentIdForEdition(null);

            logAnalyticEvent('calendar', 'edit_appt');
          }}
          handleCancel={() => {
            setSelectedAppointmentIdForDeletion(
              selectedAppointmentIdForEdition
            );
          }}
          handleClose={() => {
            setSelectedAppointmentIdForEdition(null);
          }}
        />
      )}
      {selectedAppointmentIdForDeletion && selectedAppointmentForDeletion && (
        <ConfirmationModal
          open={Boolean(selectedAppointmentIdForDeletion)}
          title="Delete this appointment?"
          description={
            selectedAppointmentForDeletion.encounterID ? (
              <p className="text-center">
                This will send a cancellation notification.
                <br />
                <span className="text-red-500">
                  There is an encounter associated to this meeting.
                </span>
              </p>
            ) : (
              'This will send a cancellation notification.'
            )
          }
          confirm="OK"
          dismiss="Cancel"
          handleClose={() => {
            setSelectedAppointmentIdForDeletion(null);
          }}
          handleConfirm={() => {
            deleteAppointment(selectedAppointmentForDeletion);

            setSelectedAppointmentIdForEdition(null);
            setSelectedAppointmentIdForDeletion(null);

            logAnalyticEvent('calendar', 'delete_appt');
          }}
          handleCancel={() => {
            setSelectedAppointmentIdForDeletion(null);
          }}
        />
      )}
    </>
  );
};
