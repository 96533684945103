import { useSnackbar } from '../../../components/Snack';
import axios from '../../../app/axiosConfig';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdatePatientProfileDTO } from '@aster/app/core/shared/dtos/patient';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../../src/mutations/utils/create-mutation-scope-id';

export const useUpdatePatientProfileMutation = (
  patientID: string,
  callbacks?: {
    onSuccess?: () => void;
    onError?: () => void;
    onMutate?: () => void;
  }
) => {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const updatePatient = async (newForm: UpdatePatientProfileDTO) =>
    axios.put(`/v2/patients/${patientID}/profile`, newForm);

  const updatePatientProfileMutation = useMutation({
    mutationFn: updatePatient,
    onMutate: callbacks?.onMutate,
    scope: {
      id: createMutationScopeID(MutationScope.PATIENT, patientID),
    },
    onSuccess: () => {
      showMessage({
        message: 'Patient updated successfully',
        type: 'success',
      });
      void queryClient.invalidateQueries({ queryKey: ['patients', patientID] });
      void queryClient.invalidateQueries({
        queryKey: ['patientProfile', patientID],
      });
      void queryClient.invalidateQueries({
        queryKey: ['patient-snapshot', patientID],
      });
      void queryClient.invalidateQueries({
        queryKey: ['patientForLabs', patientID],
      });
      void queryClient.invalidateQueries({
        queryKey: ['isPatientValidForLabs', patientID],
      });
      callbacks?.onSuccess && callbacks?.onSuccess();
    },
    onError: () => {
      showMessage({
        message: 'An error occurred while updating patient. Please try again.',
        type: 'error',
      });
      callbacks?.onError && callbacks?.onError();
    },
  });

  return { updatePatientProfileMutation };
};
