import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '../../../components/Typography';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { TryVitalResult, TryVitalResultEntry } from '@aster/app/core/shared/dtos/labs';
import dayjs from 'dayjs';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import ButtonType from '../../../components/Button';
import CircleWIthInitials from '../../../components/CircleWIthInitials';
import Tag from '../../../components/Tag';
import StyledDataGrid from '../../../components/StyledDataGrid';
import { resultSeverityClassColor } from '../../notes/sections/utils';
import { usePatientLabOrderDetails } from '../queries/get-patient-lab-order-details.query';
import { cn } from '@aster/client/utils/cn';
import FillResultsPDF from '../manually-add-results/FillResults/FillResultsPDF';
import { useLabsPDFUrl } from '../hooks/useLabsPDFUrl';
import useTheme from '@mui/system/useTheme';
import useMediaQuery from '@mui/system/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import { downLoadFileFromUrl } from '../../documents/utils/s3-files.utils';
import { useSnackbar } from '../../../components/Snack';
import { createId } from '@paralleldrive/cuid2';
import { useMemo } from 'react';

export type LabOrderDetailsProps = {
  labOrderID?: string;
  patientID?: string;
  pdfFileUrl?: string | null;
};

export const LabOrderDetails = ({
  labOrderID,
  patientID,
  pdfFileUrl,
}: LabOrderDetailsProps) => {
  const { isLoadingOrder, labOrder } = usePatientLabOrderDetails({
    labOrderID,
    patientID,
  });
  if (isLoadingOrder)
    return (
      <div className="grid place-content-center h-40">
        <CircularProgress />
      </div>
    );

  if (!labOrderID || !labOrder) return false;

  const { results, createdAt, labTest, orderedBy, status, priority } = labOrder;

  return (
    <section className="grid grid-cols-3 3xl:grid-cols-2 gap-y-5 flex-1 h-full overflow-y-hidden">
      <FillResultsPDF pdfFileUrl={pdfFileUrl} />
      <article className="flex pl-6 flex-col gap-4 col-start-2 col-end-4 3xl:col-end-3 overflow-y-auto">
        <header className="flex flex-col">
          <div className="flex justify-between">
            <Typography variant="h1" text={labTest.name} />
            <div className="flex flex-col">
              <div className="flex gap-1 items-center justify-end mb-auto">
                <CircleWIthInitials
                  name={orderedBy}
                  height={20}
                  width={20}
                  textVariant="meta"
                />
                <Typography
                  customClass="font-semibold"
                  variant="bodySmall"
                  text={orderedBy}
                />
              </div>
              <div className="flex gap-2">
                {priority && (
                  <Tag tagText="Priority" color="gray" textColor="white"></Tag>
                )}
                {status !== 'manually_uploaded' && (
                  <Tag
                    tagText="Ordered"
                    rightText={dayjs(createdAt).format('MM/DD/YYYY')}
                  ></Tag>
                )}
              </div>
            </div>
          </div>
          <div className="flex mt-2 justify-between">
            <Tag
              tagText={status}
              className="capitalize"
              textColor="white"
              color="gray"
            ></Tag>
            <div className="flex gap-2">
              <Tag
                tagText="Collected"
                rightText={
                  results?.metadata.date_collected
                    ? dayjs(results.metadata.date_collected).format(
                        'MM/DD/YYYY'
                      )
                    : '-'
                }
              ></Tag>
              <Tag
                tagText="Received"
                rightText={
                  results?.metadata.date_received
                    ? dayjs(results.metadata.date_received).format('MM/DD/YYYY')
                    : '-'
                }
              ></Tag>
              <Tag
                tagText="Reported"
                rightText={
                  results?.metadata.date_reported
                    ? dayjs(results.metadata.date_reported).format('MM/DD/YYYY')
                    : '-'
                }
              ></Tag>
            </div>
          </div>
        </header>

        <Typography
          variant="body"
          customClass="capitalize p-2 w-full text-center bg-neutral-100 rounded-lg"
          text={results?.metadata.interpretation ?? '-'}
        />

        {results ? (
          <LabResults results={results}></LabResults>
        ) : (
          <Typography
            text="The results have not arrived yet. Please try later."
            variant="body"
            customClass="mt-4"
          />
        )}
      </article>
    </section>
  );
};

const LabResults = ({ results }: { results: TryVitalResult | null }) => {
  const rows = useMemo(
    () =>
      (results ?? { results: [] }).results.map((result) => {
        return {
          id: createId(),
          name: result.name,
          result: result.result,
          unit: result.unit,
          min_range_value: result.min_range_value,
          max_range_value: result.max_range_value,
          interpretation: result.interpretation,
          notes: result.notes,
          is_above_max_range: result.is_above_max_range,
          is_below_min_range: result.is_below_min_range,
          type: result.type,
        };
      }),
    [results]
  );

  if (!results) return false;

  const isOutOfRange = (resultEntry: TryVitalResultEntry) =>
    resultEntry.is_above_max_range ||
    resultEntry.is_above_max_range ||
    resultEntry.interpretation === 'abnormal' ||
    resultEntry.interpretation === 'critical';

  const columns: GridColDef[] = [
    {
      field: 'interpretation',
      headerClassName: 'bg-grayBackground',
      renderHeader: () => (
        <div className="flex justify-center items-center rounded-full bg-asterGray opacity-75 h-5 w-5">
          <PriorityHighIcon className="text-white text-bodySmall" />
        </div>
      ),
      width: 70,
      renderCell: (params: GridCellParams) => {
        const result = params.row as TryVitalResultEntry;
        return (
          isOutOfRange(result) && (
            <div
              className={cn(
                'rounded-full h-4 w-4 text-white grid place-content-center',
                resultSeverityClassColor(result.interpretation ?? '', 'bg')
              )}
            >
              <p className="text-bodySmall">
                {result.is_above_max_range ? 'H' : 'L'}
              </p>
            </div>
          )
        );
      },
    },
    {
      field: 'name',
      headerClassName: 'bg-grayBackground text-bodySmall',
      headerName: 'Variable',
      flex: 1,
    },
    {
      field: 'result',
      headerClassName: 'bg-grayBackground text-bodySmall',
      headerName: 'Result',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const result = params.row as TryVitalResultEntry;
        return (
          <div className="flex items-center gap-2">
            <Typography
              variant="bodySmall"
              customClass={`font-semibold ${resultSeverityClassColor(
                result.interpretation ?? ''
              )}`}
              text={
                result.type === 'comment'
                  ? result.result
                  : `${result.result} ${result.unit}`
              }
            />
          </div>
        );
      },
    },
    {
      field: 'range',
      headerName: 'Ref. Range',
      headerClassName: 'bg-grayBackground text-bodySmall',
      width: 100,
      renderCell: (params: GridCellParams) => {
        const result = params.row as TryVitalResultEntry;
        return (
          <Typography
            customClass="justify-self-end font-semibold"
            variant="bodySmall"
            text={`${result.min_range_value ?? ''} - ${
              result.max_range_value ?? ''
            }`}
          />
        );
      },
    },
    {
      field: 'notes',
      headerClassName: 'bg-grayBackground text-bodySmall',
      headerName: 'Notes',
      flex: 1.5,
      renderCell: (params: GridCellParams) => {
        const result = params.row as TryVitalResultEntry;
        return (
          <Typography
            customClass="justify-self-end font-semibold"
            variant="bodySmall"
            text={result.notes ?? '-'}
          />
        );
      },
    },
  ];

  return (
    <StyledDataGrid
      disableColumnFilter
      disableDensitySelector
      disableColumnSelector
      disableColumnMenu
      getRowClassName={(params) =>
        cn(
          'text-bodySmall',
          resultSeverityClassColor(params.row.interpretation ?? '')
        )
      }
      className="col-span-2"
      hideFooterPagination={true}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
    />
  );
};

export const LabOrderDetailsDialog = (
  props: DialogProps & LabOrderDetailsProps & { handleClose: () => void }
) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { pdfFileUrl } = useLabsPDFUrl(props.labOrderID as string);
  const { showMessage } = useSnackbar();

  return (
    <Dialog
      fullScreen={fullScreen}
      classes={{
        paper: cn('overflow-hidden h-full'),
      }}
      maxWidth="xl"
      {...props}
    >
      <DialogContent className="flex flex-col p6 pb-4">
        <LabOrderDetails pdfFileUrl={pdfFileUrl} {...props}></LabOrderDetails>
      </DialogContent>
      <DialogActions>
        <ButtonType variant="text" text="Close" onClick={props.handleClose} />
        <ButtonType
          disabled={!pdfFileUrl}
          onClick={() =>
            downLoadFileFromUrl(pdfFileUrl as string, 'results.pdf', () =>
              showMessage({
                message:
                  'An error occured while downloading the results PDF. Please try again.',
                type: 'error',
              })
            )
          }
          variant="contained"
          text="Download PDF"
        />
      </DialogActions>
    </Dialog>
  );
};
