import { Apartment, PersonAddAlt } from '@mui/icons-material';
import { CircularProgress, Divider, Snackbar } from '@mui/material';
import {
  GridColDef,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../../app/axiosConfig';
import ButtonType from '../../components/Button';
import CircleWithInitials from '../../components/CircleWIthInitials';
import Typography from '../../components/Typography';
import InviteMembersModal from './modals/InviteMembersModal';
import MemberOptionsMenu from './MemberOptionsMenu';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import RevokeInviteModal from './modals/RevokeInviteModal';
import { colors } from '../../theme';
import StyledDataGrid from '../../components/StyledDataGrid';
import { StaffListItemDTO } from '@aster/app/core/shared/dtos/staff';
import {
  MutationScope,
  createMutationScopeID,
} from '../../mutations/utils/create-mutation-scope-id';
import useInviteMembersMutation from './mutations/invite-members-mutation';

const Members = () => {
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openRevokeModal, setOpenRevokeModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const queryClient = useQueryClient();
  const profile: any = queryClient.getQueryData(['getProfile']);

  const revokeInvite = async (token: string) =>
    axios.post(`token/${token}/invalidate`);

  const resendInvite = async (email: string) => {
    setSnackBarMessage(`Resent invite for ${email}`);
    setOpenSnackbar(true);
    await axios.post(`staffs/invite`, { email }).then(() => {
      void queryClient.invalidateQueries({ queryKey: ['pendingInvites'] });
    });
  };

  const openRevokeModalHandler = (token: string, email: string) => {
    setSelectedToken(token);
    setSelectedEmail(email);
    setOpenRevokeModal(true);
  };

  const fetchMembers = async () => {
    const response = await axios.get<StaffListItemDTO[]>('staffs');
    return response.data;
  };

  const fetchPendingInvites = async () => {
    const response = await axios.get(`staffs/pending-invites`);
    return response.data;
  };

  const revokeStaffInviteMutation = useMutation({
    mutationKey: ['revokeStaffInvite'],
    mutationFn: revokeInvite,
    scope: {
      id: createMutationScopeID(MutationScope.MEMBER),
    },
    onMutate: async (token) => {
      setSnackBarMessage(`Revoked invite for ${selectedEmail}`);
      setOpenSnackbar(true);
      const previousPendingInvites = queryClient.getQueryData([
        'pendingInvites',
      ]);
      queryClient.setQueryData(['pendingInvites'], (old: any) => {
        return old.filter((f: any) => f.token !== token);
      });
      return { previousPendingInvites };
    },
    onError: (_err, _token, context) => {
      queryClient.setQueryData(
        ['pendingInvites'],
        context?.previousPendingInvites
      );
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['pendingInvites'] });
    },
  });

  const { inviteMembersMutation } = useInviteMembersMutation();

  const { data: pendingInvites, isLoading: pendingInvitesLoading } = useQuery({
    queryKey: ['pendingInvites'],
    queryFn: fetchPendingInvites,
  });
  const { data: staffData, isLoading } = useQuery({
    queryKey: ['staffs'],
    queryFn: fetchMembers,
  });

  const gridRows = useMemo(() => {
    if (!pendingInvitesLoading && !isLoading) {
      const staffRow = staffData
        ? staffData.map((r: any) => ({
            id: r.id,
            name: `${r.firstName} ${r.lastName}`,
            email: r.email,
            role:
              r.role.charAt(0).toUpperCase() +
              r.role.slice(1) +
              ' - Full Access',
          }))
        : [];
      const pendingStaffRows = pendingInvites
        ? pendingInvites
            .filter((f: any) => f.token && f.metadata.targetEmail)
            .map((r: any) => {
              if (r.metadata.targetEmail && r.token) {
                return {
                  id: r.token,
                  name: 'Pending...',
                  email: r.metadata.targetEmail,
                  role: '',
                };
              }
              return undefined;
            })
        : [];
      return [...staffRow, ...pendingStaffRows];
    }
    return [];
  }, [pendingInvitesLoading, isLoading, staffData, pendingInvites]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      width: 400,
      renderCell: (params) => {
        const { name, email, role } = params.row;
        return (
          <div className="flex items-center">
            {role.length ? (
              <CircleWithInitials
                name={name}
                height={40}
                width={40}
                textVariant="bodySmall"
              />
            ) : (
              <div className="h-[40px] w-[40px] mx-2 flex items-center justify-center">
                <HourglassBottomOutlinedIcon />
              </div>
            )}
            <div className="flex flex-col m-1">
              {role ? (
                <>
                  <Typography
                    customClass="font-semibold"
                    variant="body"
                    text={name}
                  />
                  <Typography variant="bodySmall" text={email} />
                </>
              ) : (
                <>
                  <Typography
                    customClass="font-semibold text-gray-500"
                    variant="body"
                    text={name}
                  />
                  <Typography
                    variant="bodySmall"
                    customClass="text-gray-500"
                    text={email}
                  />
                </>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: 'role',
      width: 200,
    },
    {
      field: 'id',
      flex: 1,
      align: 'right',

      renderCell: (params) => {
        const { role, id, email } = params.row;
        if (role) {
          return null;
        }
        return (
          <MemberOptionsMenu
            token={id}
            email={email}
            sendInvite={() => resendInvite(email)}
            openRevokeModal={() => openRevokeModalHandler(id, email)}
          />
        );
      },
    },
  ];

  const CustomToolBar = () => (
    <div className="flex justify-between m-2">
      <div className="flex flex-1">
        <GridToolbarQuickFilter className="flex-1 mr-8" />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </div>
      <span className="flex items-center text-h6 text-gray-500 mx-4">
        {staffData?.length ?? 0}&nbsp;member
        {(staffData?.length ?? 0) > 1 ? 's' : ''}
      </span>
    </div>
  );

  if (isLoading || pendingInvitesLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full py-4">
      <div className="flex justify-between items-center px-10">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <Apartment sx={{ color: '#586068' }} />
            <span className="text-h6 text-gray-500 ml-2 ">
              {profile ? profile.practiceName : null}
            </span>
          </div>
          <p className="text-h4 text-black font-semibold m-0">Members</p>
        </div>
        <ButtonType
          onClick={() => setOpenInviteModal(true)}
          variant="contained"
          text="Invite people"
          icon
          specificIcon={<PersonAddAlt className="mr-2" />}
        />
      </div>
      <Divider className="my-4" />
      <div className="full-w-container flex-col">
        <Typography variant="h4" text="Manage members" />
        <Typography
          customClass="mt-1 mb-6"
          variant="body"
          text="Manage practice members and their permissions"
        />

        <StyledDataGrid
          className="grid w-full"
          pageSizeOptions={[10, 25, 50]}
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          disableColumnMenu
          rows={gridRows}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 6,
              },
            },
          }}
          // columnHeaders config gives console warning but removes tables headers
          slots={{ toolbar: CustomToolBar, columnHeaders: () => null }}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
        />
      </div>
      <InviteMembersModal
        open={openInviteModal}
        title="Invite to your practice"
        description="By adding this member you are granting them full access."
        confirm="Send invites"
        dismiss="Cancel"
        handleClose={() => setOpenInviteModal(false)}
        handleConfirm={inviteMembersMutation}
        handleCancel={() => setOpenInviteModal(false)}
      />
      <RevokeInviteModal
        open={openRevokeModal}
        title="Revoke invite"
        email={selectedEmail}
        token={selectedToken}
        confirm="Confirm"
        dismiss="Cancel"
        handleClose={() => setOpenRevokeModal(false)}
        handleConfirm={() => revokeStaffInviteMutation.mutate(selectedToken)}
        handleCancel={() => setOpenRevokeModal(false)}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={3000}
        message={snackBarMessage}
        sx={{
          backgroundColor: colors.gray,
          color: colors.white,
        }}
      />
    </div>
  );
};

export default Members;
