import { LabTestDTO } from '@aster/app/core/shared/dtos/labs';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { distance } from 'fastest-levenshtein';
import { useLabsStore } from '../store/labs.store';

const fetchLabTests = async () => {
  const response = await axios.get<LabTestDTO[]>(`/v2/labs/tests`);
  return response.data;
};
export function useAvailableLabTests() {
  const { data: availableLabTests, isLoading: areLabTestsLoading } = useQuery({
    queryKey: ['labTests'],
    queryFn: fetchLabTests,
  });

  const searchQuery = useLabsStore((state) => state.searchQuery);

  const filteredLabTests = useMemo(() => {
    if (!searchQuery) return availableLabTests;
    return availableLabTests
      ?.map((labTest) => ({
        ...labTest,
        distance: distance(
          labTest.name.toLowerCase(),
          searchQuery.toLowerCase()
        ),
      }))
      .filter((labTest) => labTest.distance < labTest.name.length)
      .sort((a, b) => a.distance - b.distance);
  }, [availableLabTests, searchQuery]);

  return { availableLabTests, filteredLabTests, areLabTestsLoading };
}
