import {
  faChartSimple,
  faFileDownload,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';

import { Flowsheet } from '../components/Flowsheet';
import { SectionTitle } from '../components/SectionTitle';
import { useDownloadFlowsheetMutation } from '../mutations/use-download-flowsheet-mutation';
import { useFlowsheetQuery } from '../queries/use-flowsheet-query';

import ButtonType from '../../../../../components/Button';

export const FlowsheetSection = ({
  patient,
}: {
  patient: PatientProfileForEncounterDTO;
}) => {
  const { flowsheet = [] } = useFlowsheetQuery(patient);

  const { downloadFlowsheet, isGeneratingFlowsheet } =
    useDownloadFlowsheetMutation();

  if (!flowsheet.length) return null;

  return (
    <div className="space-y-4">
      <SectionTitle
        icon={<FontAwesomeIcon icon={faChartSimple} />}
        action={
          <ButtonType
            variant="outlined"
            text="Download Flowsheet"
            classes="px-4 py-1 max-h-8 text-[14px]"
            onClick={() => downloadFlowsheet({ patient, flowsheet })}
            loading={isGeneratingFlowsheet}
            icon
            specificIcon={
              <FontAwesomeIcon
                icon={faFileDownload}
                className="mr-2 text-body"
              />
            }
            notRounded
          />
        }
      >
        Prenatal Flowsheet
      </SectionTitle>
      <Flowsheet rows={flowsheet} />
    </div>
  );
};
