import axios from '../../../app/axiosConfig';
import { LabsOrderDetailsDTO } from '@aster/app/core/shared/dtos/labs';
import { useQuery } from '@tanstack/react-query';

const fetchLabOrderDetails = async (labOrderID: string) => {
  const response = await axios.get<LabsOrderDetailsDTO>(
    `/v2/labs/orders/${labOrderID}`
  );
  return response.data;
};
export function usePatientLabOrderDetails({
  patientID,
  labOrderID,
}: {
  patientID: string | undefined;
  labOrderID: string | undefined;
}) {
  const { data: labOrder, isLoading: isLoadingOrder } = useQuery({
    queryKey: ['patientLabOrders', patientID, labOrderID],
    queryFn: () => fetchLabOrderDetails(labOrderID as string),
    enabled: labOrderID != undefined && patientID != undefined,
  });

  return { labOrder, isLoadingOrder };
}
