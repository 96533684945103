import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  AppointmentDTO,
  UpdateAppointmentDTO,
} from '@aster/app/core/shared/dtos/appointment';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import { useSnackbar } from '../../../components/Snack';
import axios from '../../../app/axiosConfig';
import { logAnalyticEvent } from '../../../app/firebase';
import { AxiosResponse } from 'axios';

const updateAppointmentInner = async (
  newAppt: UpdateAppointmentDTO & { loading: boolean }
) => axios.put(`/appointments/${newAppt.id}`, newAppt);

export const useUpdateAppointmentMutation = ({
  timeRef,
  onMutate,
  onSuccess,
  onError,
}: {
  timeRef: React.MutableRefObject<{ startTime: string; endTime: string }>;
  onMutate?: () => void;
  onSuccess?: (
    _: AxiosResponse<any, any>,
    variables: UpdateAppointmentDTO
  ) => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();
  const { showMessage } = useSnackbar();

  const {
    mutate: updateAppointment,
    isPending: isUpdatingAppointment,
    ...rest
  } = useMutation({
    mutationKey: ['updateAppt'],
    mutationFn: updateAppointmentInner,
    scope: {
      id: createMutationScopeID(MutationScope.APPOINTMENT),
    },
    onMutate: (newAppt) => {
      onMutate?.();
      newAppt.loading = true;

      const previousFetchedAppts = queryClient.getQueryData([
        'fetchAppts',
        timeRef.current.startTime,
        timeRef.current.endTime,
      ]);
      queryClient.setQueryData<Partial<AppointmentDTO>[]>(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        (old) => {
          if (!old) return [newAppt];
          return old.map((e) => (e.id === newAppt.id ? newAppt : e));
        }
      );
      return { previousFetchedAppts };
    },
    onError: (error, newAppt, context) => {
      queryClient.setQueryData(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        context?.previousFetchedAppts
      );
      showMessage({
        message:
          'An error occured while updating the appointment. Please try again!',
        type: 'error',
      });
      onError?.();
    },
    onSuccess: (_, variables) => {
      showMessage({
        message: 'Appointment updated successfully!',
        type: 'success',
      });
      logAnalyticEvent('calendar', 'update_appt');
      onSuccess?.(_, variables);
    },
  });

  return { updateAppointment, isUpdatingAppointment, ...rest };
};
