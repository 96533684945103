import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Authenticator } from '@aws-amplify/ui-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { store } from './app/store';

import App from './app/app';
import { initDatadogRum } from './configurations/datadog-rum';
import { configureAmplify } from './configurations/amplify';
import { initializeMuiTheme } from './configurations/initialize-mui-theme';
import { configureTanstackQueryClient } from './configurations/configure-ttq-client';
import { SnackbarProvider } from './components/Snack';
import 'react-toastify/dist/ReactToastify.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { configureReactPDF } from './configurations/configure-react-pdf';
import '@fontsource-variable/figtree';

configureReactPDF();
initDatadogRum();
configureAmplify();
const MUItheme = initializeMuiTheme();
const router = createBrowserRouter([{ path: '*', Component: App }]);
const queryClient = configureTanstackQueryClient();

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    twsm: true; // adds the `mobile` breakpoint
    twmd: true;
    twlg: true;
    twxl: true;
    tw2xl: true;
  }
}

console.log(import.meta.env);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <Authenticator.Provider>
      <StyledEngineProvider injectFirst>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={MUItheme}>
            <Provider store={store}>
              <SnackbarProvider>
                <RouterProvider router={router} />
              </SnackbarProvider>
            </Provider>
          </ThemeProvider>
        </QueryClientProvider>
      </StyledEngineProvider>
    </Authenticator.Provider>
  </StrictMode>
);
