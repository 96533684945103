import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  AppointmentDTO,
  UpdateAppointmentDTO,
} from '@aster/app/core/shared/dtos/appointment';

import {
  createMutationScopeID,
  MutationScope,
} from '../../../../../mutations/utils/create-mutation-scope-id';

import { useSnackbar } from '../../../../../components/Snack';

import axios from '../../../../../app/axiosConfig';
import { logAnalyticEvent } from '../../../../../app/firebase';

const editAppointmentInner = async (appointment: UpdateAppointmentDTO) => {
  return await axios.put(`/appointments/${appointment.id}`, appointment);
};

export const useEditAppointmentMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const queryClient = useQueryClient();

  const { showMessage } = useSnackbar();

  const {
    mutate: editAppointment,
    isPending: isEditingAppointment,
    ...rest
  } = useMutation({
    mutationKey: ['editAppointment'],
    mutationFn: editAppointmentInner,
    scope: {
      id: createMutationScopeID(MutationScope.APPOINTMENT),
    },
    onMutate: (appointment: UpdateAppointmentDTO & { loading?: boolean }) => {
      const [patientID] = appointment.invitedPatientIDs;

      appointment.loading = true;

      const previousAppointments = queryClient.getQueryData([
        'appointmentsByPatient',
        patientID,
      ]);
      queryClient.setQueryData<Partial<AppointmentDTO>[]>(
        ['appointmentsByPatient', patientID],
        (old = []) => {
          return old.map((oldAppointment) =>
            oldAppointment.id === appointment.id ? appointment : oldAppointment
          );
        }
      );
      return { previousAppointments };
    },
    onError: (_, appointment, context) => {
      const [patientID] = appointment.invitedPatientIDs;

      queryClient.setQueryData(
        ['appointmentsByPatient', patientID],
        context?.previousAppointments
      );

      showMessage({
        message: 'Error updating appointment',
        type: 'error',
      });

      onError?.();
    },
    onSuccess: (_, variables) => {
      const [patientID] = variables.invitedPatientIDs;

      void queryClient.invalidateQueries({
        queryKey: ['appointmentsByPatient', patientID],
      });

      logAnalyticEvent('calendar', 'create_appt');
      showMessage({
        message: 'Appointment updated',
        type: 'success',
      });

      onSuccess?.();
    },
  });

  return {
    editAppointment,
    isEditingAppointment,
    ...rest,
  };
};
