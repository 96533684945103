import {
  LabTestDTO,
  TryVitalCreateOrderAOEAnswerDTO,
  TryVitalMarkerQuestionDTO,
} from '@aster/app/core/shared/dtos/labs';
import { StateCreator } from 'zustand';

type CreateLabsOrderState = {
  selectedLabTest: LabTestDTO | null;
  searchQuery: string;
  answers: Map<number, TryVitalCreateOrderAOEAnswerDTO>;
  newOrderIDs: string[];
  isCheckoutQuestionsVisible: boolean;
};

type CreateLabsOrderAction = {
  selectLabTest: (labTest: LabTestDTO | null) => void;
  initializeAsnwers: (questions: TryVitalMarkerQuestionDTO[]) => void;
  changeAnswer: (answer: string, questionId: number) => void;
  addNewOrderID: (orderID: string) => void;
  search: (query: string) => void;
  showCheckoutQuestions: () => void;
};
export type CreateLabsSlice = CreateLabsOrderState & CreateLabsOrderAction;

export const createCreateLabsSlice: StateCreator<CreateLabsSlice> = (set) => ({
  // State
  selectedLabTest: null,
  searchQuery: '',
  answers: new Map(),
  newOrderIDs: [],
  isCheckoutQuestionsVisible: false,
  // Actions
  selectLabTest: (labTest) => {
    set({ selectedLabTest: labTest });
  },
  changeAnswer: (answer, questionId) => {
    set((state) => {
      const answers = new Map(state.answers);
      if (answers.has(questionId)) {
        answers.set(questionId, {
          ...(answers.get(questionId) as TryVitalCreateOrderAOEAnswerDTO),
          answer,
        });
      }

      return { answers };
    });
  },
  addNewOrderID: (orderID) => {
    set((state) => {
      if (!orderID) return state;
      const newOrderIDs = [...state.newOrderIDs, orderID];
      return { newOrderIDs };
    });
  },
  search: (query) => {
    set({ searchQuery: query });
  },
  showCheckoutQuestions: () => {
    set({ isCheckoutQuestionsVisible: true });
  },
  initializeAsnwers: (questions) => {
    set({
      answers: new Map(
        questions.map((question) => [
          question.id,
          {
            question_id: question.id,
            marker_id: question.markerId,
            answer: '',
          },
        ])
      ),
    });
  },
});
