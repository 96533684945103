import { useQuery } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';
import { PatientInfoDTO } from '@aster/app/core/shared/dtos/patient';

const fetchPatient = async (patientID: string) => {
  const response = await axios.get<PatientInfoDTO>(`/v2/patients/${patientID}`);
  return response.data;
};

export const usePatientInfoQuery = (patientID?: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['patients', patientID],
    queryFn: () => fetchPatient(patientID as string),
    enabled: !!patientID,
  });
  return {
    patientInfo: data,
    isPatientLoading: isLoading,
    refetchPatient: refetch,
  };
};
