import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  styled,
} from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AsterLogo from '../assets/aster';
import ButtonType from '../components/Button';
import BasicTextfield from '../components/Textfield';
import { colors } from '../theme';
import { useAuth } from './AuthProvider';
import { z } from 'zod';

const CssInput = styled(FilledInput)({
  '&.MuiFilledInput-root': {
    color: colors.gray,
    overflow: 'hidden',
    borderRadius: '10px',
    backgroundColor: colors.white,
    border: '1px solid',
    width: '100%',
    borderColor: colors.grayLight,
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&.Mui-focused': {
      backgroundColor: colors.white,
      boxShadow: colors.grayLight,
    },
    '&.Mui-error': {
      borderColor: colors.red,
    },
    '&.MuiFormLabel-root': {
      color: colors.primary,
      fontWeight: 600,
    },
  },
});

const CssLabel = styled(InputLabel)({
  '&.MuiInputLabel-root': {
    '&.Mui-focused': {
      fontWeight: 600,
      color: colors.primary,
    },
    '&.Mui-error': {
      color: colors.red,
    },
  },
});

const SignIn = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const form = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      const { username, password } = value;

      setLoading(true);

      await login({ username, password }).catch((err) => {
        setError(err.message);
      });

      setLoading(false);
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { login } = useAuth();

  return (
    <div className="flex flex-col items-center h-screen w-screen pt-8 md:pt-[5%] bg-grayBackground">
      <div className="align-middle mb-10">
        <AsterLogo />
      </div>
      <form
        className="flex flex-col items-center lg:w-1/3 sm:w-full  min-h-min rounded-[20px] bg-white p-11 max-w-[480px]"
        onSubmit={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <div className="text-h4 text-primary font-semibold">Welcome back!</div>
        <div className="text-body text-gray-500 mb-9">
          Let's log into your account
        </div>

        <form.Field
          name="username"
          validators={{ onBlur: z.string().email('Invalid email') }}
          children={(field) => (
            <BasicTextfield
              id="email"
              variant="filled"
              label="Work email"
              name="username"
              value={field.state.value}
              onChange={(evt) => field.handleChange(evt.target.value)}
              onBlur={field.handleBlur}
              error={!!error || field.state.meta.errors.length > 0}
              helperText={field.state.meta.errors.join('\r')}
              width="100%"
              classes="mb-5"
            />
          )}
        />
        <form.Field
          name="password"
          children={(field) => (
            <FormControl variant="filled" fullWidth>
              <CssLabel htmlFor="filled-adornment-password" error={!!error}>
                Password
              </CssLabel>
              <CssInput
                id="filled-adornment-password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                disableUnderline={true}
                value={field.state.value}
                onChange={(evt) => field.handleChange(evt.target.value)}
                error={!!error}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        />
        {error && (
          <div className="grid grid-cols-6 w-full ml-10">
            <p className="text-red-500 !content-start col-span-5">{error}</p>
          </div>
        )}

        <div className="mt-3 mb-5">
          <p
            className="text-secondary cursor-pointer hover:opacity-80"
            onClick={() => navigate('/forgotpassword')}
          >
            Forgot your password?
          </p>
        </div>
        <form.Subscribe
          children={() => (
            <ButtonType
              classes="px-12"
              variant="contained"
              text="Sign in"
              type="submit"
              loading={loading}
            />
          )}
        />
      </form>
      <div className="flex gap-2 text-center text-gray-500 sm:text-left bg-grayBackground my-4 sm:my-6">
        <span>Don&apos;t have an account yet?</span>
        <span
          className="text-secondary cursor-pointer hover:opacity-80"
          onClick={() => navigate('/signup')}
        >
          Sign up and get started!
        </span>
      </div>
    </div>
  );
};

export default SignIn;
