import { EventSourceInput } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';

import {
  AppointmentTypeDisplayNames,
  AppointmentWithInviteesDTO,
} from '@aster/app/core/shared/dtos/appointment';

import { colorPerInitial, rgbWithOpacity } from '../../../utils';

// When working with calendar events, more specifically, using
// shared components like `EditApptModal` or `CreateApptModal`
// we don't receive the callback data (appointment information
// and/or new event info) in an easily accessible structure.
//
// Instead, we receive an `EventImpl` object with a shape that
// fits the calendar's inner workings, but not the rest of the
// app (which relies on the `AppointmentDTO`). Similarily, the
// calendar expects to receive an `EventImpl` to set its inner
// state.
//
// This function maps the `AppointmentWithInviteesDTO` type to
// a calendar-friendly `EventImpl` object.
export const appointmentToEventSource = (
  event: AppointmentWithInviteesDTO & {
    loading?: boolean;
  }
) => {
  return {
    start: new Date(event.startTime),
    end: new Date(event.endTime),
    _def: {
      publicId: event.id,
      allDay: false,
      hasEnd: true,
      title: event.type,
      extendedProps: {
        id: event.id,
        originalId: event.id,
        invitedPatients: event.invitedPatients,
        invitedStaffs: event.invitedStaff,
        title: event.type,
        start: event.startTime,
        end: event.endTime,
        note: event.note,
        publicId: event.id,
        appointment: AppointmentTypeDisplayNames[event.type],
        telehealth: event.telehealth,
        backgroundColor: rgbWithOpacity(
          colorPerInitial(event.invitedPatients?.[0]?.firstName?.charAt(0))
        ),
        borderColor: colorPerInitial(
          event.invitedPatients?.[0]?.firstName?.charAt(0)
        ),
        editable: event.id && !event.loading,
        loading: event.loading ? event.loading : false,
      } as EventSourceInput,
    },
  } as EventImpl;
};
