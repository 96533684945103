import React from 'react';
import { GTPALDTO, PatientProblem } from '@aster/app/core/shared/dtos/patient';
import Tag from '../../../../src/components/Tag';
import { formatMRN } from '../../../../src/utils';
import dayjs from 'dayjs';

type GTPALProps = {
  gtpal: GTPALDTO;
  problemList?: PatientProblem[];
  dateOfBirth?: string | null;
  mrn?: string | null;
  calculatedDueDate?: string | null;
  gestationalAge?: string | null;
  isPregnant?: boolean | null;
};

const GPTAL = ({
  gtpal,
  problemList,
  dateOfBirth,
  mrn,
  calculatedDueDate,
  gestationalAge,
  isPregnant,
}: GTPALProps) => {
  const gtpalInfoFull =
    gtpal &&
    `G${gtpal.gravida} T${gtpal.termBirths} P${gtpal.pretermBirths} A${gtpal.abortionsAndMiscarriages} L${gtpal.livingChildren}`;
  const hasAdvancedMaternalAge = Boolean(
    problemList?.find((problem) => problem.abbr === 'AMA')
  );

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-col gap-[5px]">
        <div className="flex flex-wrap gap-[5px]">
          <div className="flex flex-wrap gap-[5px]">
            {mrn && (
              <Tag tooltip="Medical Record Number" tagText={formatMRN(mrn)} />
            )}
            {dateOfBirth && (
              <Tag
                tooltip="Date of Birth"
                tagText="DOB"
                rightText={dayjs(dateOfBirth).format('MM/DD/YYYY')}
              />
            )}
            {isPregnant && gestationalAge && (
              <Tag
                tooltip="Gestational Age"
                tagText="GA"
                rightText={gestationalAge}
              />
            )}
            {isPregnant && calculatedDueDate && (
              <Tag
                tooltip="Estimated Due Date"
                tagText="EDD"
                rightText={dayjs(calculatedDueDate).format('MM/DD/YYYY')}
              />
            )}
          </div>
          <div className="flex gap-[5px]">
            {gtpalInfoFull && (
              <Tag
                tooltip={gtpalInfoFull}
                tagText={gtpalInfoFull}
                className="font-semibold"
                textColor="white"
                color="gray-500"
              />
            )}
            {hasAdvancedMaternalAge && (
              <Tag
                tooltip="Advanced Maternal Age"
                tagText="AMA"
                className="opacity-80  font-semibold"
                color="beige"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GPTAL;
