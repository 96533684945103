import { CircularProgress } from '@mui/material';

import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';

import { AppointmentsSection } from './sections/Appointments';
import { FlowsheetSection } from './sections/Flowsheet';
import { LabOrdersSection } from './sections/LabOrders';

type OverviewTabProps = {
  patient: PatientProfileForEncounterDTO;
};

const OverviewTab = ({ patient }: OverviewTabProps) => {
  if (!patient)
    return (
      <div className="text-center w-full h-[50%] content-center">
        <CircularProgress />
      </div>
    );

  return (
    <div className="space-y-9 my-4">
      <FlowsheetSection patient={patient} />
      <AppointmentsSection patient={patient} />
      <LabOrdersSection patient={patient} />
    </div>
  );
};

export default OverviewTab;
