import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const fetchPatientSnapshot = async (encounterID: string) => {
  const response = await axios.get<PatientProfileForEncounterDTO>(
    `/v2/patients/snapshot/by-encounter/${encounterID}`
  );
  return response.data;
};

export function usePatientSnapshotQuery(encounterID?: string) {
  const { data: patientSnapshot, isLoading: isPatientSnapshotLoading } =
    useQuery({
      queryKey: ['patient-snapshot', encounterID],
      queryFn: () => fetchPatientSnapshot(encounterID as string),
      enabled: !!encounterID,
    });
  return { patientSnapshot, isPatientSnapshotLoading };
}
