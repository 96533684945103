import { Link } from 'react-router-dom';

import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import { faVial } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';

import { EmptyState } from '../components/EmptyState';
import { LabOrders } from '../components/LabOrder';
import { SectionTitle } from '../components/SectionTitle';
import { useLabOrdersQuery } from '../queries/use-lab-orders-query';

import { isPatientReadonly } from '../../../utils/is-patient-readonly';

import ButtonType from '../../../../../components/Button';

export const LabOrdersSection = ({
  patient,
}: {
  patient: PatientProfileForEncounterDTO;
}) => {
  const { labOrders = [], areLabOrdersLoading } = useLabOrdersQuery(patient);

  return (
    <div className="space-y-4">
      <SectionTitle icon={<FontAwesomeIcon icon={faVial} />}>
        Recent Lab Results
      </SectionTitle>
      {areLabOrdersLoading ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : labOrders.length ? (
        <LabOrders orders={labOrders} />
      ) : (
        <EmptyState
          icon={<FontAwesomeIcon icon={faVial} />}
          title={`${patient.name.split(' ')?.[0]}'s lab order list is empty`}
          description={'Get started by creating their first lab order'}
          action={
            <Link
              to={`/patientProfile/${patient.patientID}?tab=5&action=create`}
            >
              <ButtonType
                variant="contained"
                text="Create Lab Order"
                disabled={isPatientReadonly(patient)}
              />
            </Link>
          }
        />
      )}
    </div>
  );
};
