import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AppointmentDTO } from '@aster/app/core/shared/dtos/appointment';
import {
  createMutationScopeID,
  MutationScope,
} from '../../../mutations/utils/create-mutation-scope-id';
import { useSnackbar } from '../../../components/Snack';
import axios from '../../../app/axiosConfig';
import { AxiosResponse } from 'axios';

const deleteAppointmentInner = (id: string) => {
  return axios.delete(`/appointments/${id}`);
};

export const useDeleteAppointmentMutation = ({
  timeRef,
  onSuccess,
  onError,
}: {
  timeRef: React.MutableRefObject<{ startTime: string; endTime: string }>;
  onSuccess?: (_: AxiosResponse<any, any>) => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();
  const { showMessage } = useSnackbar();

  const {
    mutate: deleteAppointment,
    isPending: isDeletingAppointment,
    ...rest
  } = useMutation({
    mutationKey: ['deleteAppt'],
    mutationFn: deleteAppointmentInner,
    scope: {
      id: createMutationScopeID(MutationScope.APPOINTMENT),
    },
    onMutate: (id) => {
      const previousFetchedAppts = queryClient.getQueryData([
        'fetchAppts',
        timeRef.current.startTime,
        timeRef.current.endTime,
      ]);
      queryClient.setQueryData<AppointmentDTO[]>(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        (old) => {
          if (!old) return [];
          return old.filter((e) => e.id !== id);
        }
      );
      return { previousFetchedAppts };
    },
    onError: (error, appt, context) => {
      queryClient.setQueryData(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        context?.previousFetchedAppts
      );
      showMessage({
        message:
          'An error occured while deleting appointment. Please try again!',
        type: 'error',
      });
      onError?.();
    },
    onSuccess: (_) => {
      showMessage({
        message: 'Appointment deleted successfully!',
        type: 'success',
      });
      onSuccess?.(_);
    },
  });
  return { deleteAppointment, isDeletingAppointment, ...rest };
};
