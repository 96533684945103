import { useEffect, useMemo, useRef, useState } from 'react';
import { Divider, CircularProgress } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import CalendarSidebar from './CalendarSidebar';
import CalendarHeader from './CalendarHeader';
import ConfirmationModal from '../../components/ConfirmationModal';
import CreateApptModal from './modals/CreateApptModal';
import EditApptModal from './modals/EditApptModal';
import axios from '../../app/axiosConfig';
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query';
import { logAnalyticEvent } from '../../app/firebase';
import dayjs, { Dayjs } from 'dayjs';
import {
  DateSelectArg,
  DatesSetArg,
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
  EventInput,
  EventSourceInput,
} from '@fullcalendar/core';
import { CalendarWrapper } from './CalendarWrapper';
import Typography from '../../components/Typography';
import {
  AppointmentTypeDisplayNames,
  AppointmentWithInviteesDTO,
  CreateAppointmentDTO,
  InviteeDTO,
  UpdateAppointmentDTO,
} from '@aster/app/core/shared/dtos/appointment';
import { EventImpl } from '@fullcalendar/core/internal';
import { CalendarEvent, InviteeWithColor, NewEvent } from './types';
import { useAuth } from '../../authentication/AuthProvider';
import { useGetStaffs } from './queries/use-get-staffs.query';
import { colors } from '../../theme';
import { useStaffColorMap } from './hooks/use-staff-color-map';
import CalendarEventDetails from './CalendarEventDetails';
import { TemplateType } from '@aster/app/core/shared/dtos/encounter';
import { getAppointmentType } from './utils/get-appointment-type';
import { useCreateEncounterMutation } from '../patients/profileTabs/OverviewTab/mutations/use-create-encounter-mutation';
import { useCreateAppointmentMutation } from './mutations/use-create-appointment.mutation';
import { useUpdateAppointmentMutation } from './mutations/use-update-appointment.mutation';
import { useDeleteAppointmentMutation } from './mutations/use-delete-appointment.mutation';

type CalendarViewTypes =
  // 5 days
  | 'timeGridFiveDay'
  // Schedule
  | 'listMonth'
  // Month
  | 'dayGridMonth'
  // Year
  | 'multiMonthYear'
  // Week
  | 'timeGridWeek'
  // Day
  | 'timeGridDay';

const CalendarApp = () => {
  const calendarRef = useRef<FullCalendar | null>(null);
  const [currentDate, setCurrentDate] = useState<DatesSetArg>();
  const slotDuration = '00:15:00';
  const slotLabelInterval = { hours: 1 };
  const slotMinTime = '00:00:00';
  const slotMaxTime = '23:59:00';
  const [openDel, setOpenDelete] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventClickArg | null>(
    null
  );
  const [newEvent, setNewEvent] = useState<NewEvent | null>(null);
  const [showMyCalendar, setShowMyCalendar] = useState(true);
  const [selectedTeamStaff, setSelectedTeamStaff] = useState<
    InviteeWithColor[]
  >([]);
  const { profile } = useAuth();

  const today = dayjs();
  const timeRef = useRef({
    startTime: today.startOf('day').format(),
    endTime: today.add(4, 'day').startOf('day').format(),
  });

  const calendarAPI = calendarRef.current?.getApi();
  // hide event details when navigating to a new date on the calendar
  calendarAPI?.on('datesSet', (arg) => {
    setSelectedEvent(null);
  });

  const { staffColorMap, profileColor } = useStaffColorMap();

  const { createEncounter } = useCreateEncounterMutation();

  const toggleMyCalendar = () => {
    setSelectedEvent(null);
    setShowMyCalendar((prev) => !prev);
  };

  const updateSelectedTeamStaff = (teamStaff: InviteeWithColor[]) => {
    setSelectedTeamStaff(teamStaff);
    setSelectedEvent(null);
  };

  const queryClient = useQueryClient();

  const fetchAppts = async () => {
    const response = await axios.get<
      (AppointmentWithInviteesDTO & {
        loading: boolean;
        currentColor: string;
      })[]
    >(
      `appointments?startTime=${dayjs(
        timeRef.current.startTime
      ).toISOString()}&endTime=${dayjs(timeRef.current.endTime).toISOString()}`
    );
    return response.data;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'fetchAppts',
      timeRef.current.startTime,
      timeRef.current.endTime,
    ],
    queryFn: fetchAppts,
    placeholderData: keepPreviousData,
  });

  const { createAppointment } = useCreateAppointmentMutation({
    timeRef,
    onSuccess: async (_, variables) => {
      if (variables.encounterData) {
        createEncounter({
          appointmentID: _.data.appointmentID,
          patientID: variables.encounterData.patientID,
          templateType: variables.encounterData.templateType,
        });
      }
      void queryClient.invalidateQueries({
        queryKey: [
          'fetchAppts',
          timeRef.current.startTime,
          timeRef.current.endTime,
        ],
      });
    },
  });

  const { staffMembers } = useGetStaffs();

  const allTeamStaff = useMemo(() => {
    if (!staffColorMap) return [];
    return Object.values(staffColorMap).filter((s) => s.id !== profile?.id);
  }, [staffColorMap]);

  useEffect(() => {
    if (!staffMembers || !allTeamStaff) {
      return;
    }
    setSelectedTeamStaff(allTeamStaff);
  }, [staffMembers]);

  const parseEvents = (
    events: (AppointmentWithInviteesDTO & {
      loading: boolean;
      currentColor: string;
    })[]
  ): CalendarEvent[] => {
    return events
      .map((event) => {
        const color = event.currentColor
          ? event.currentColor
          : (staffColorMap && profile?.id === event.ownerID) || event.loading
          ? profileColor
          : staffColorMap[event.ownerID]?.color;

        const colorClass = `!bg-${color}-100 !border-${color}-300`;

        const parsedEvent = {
          id: event.id,
          originalId: event.id,
          invitedPatients: event.invitedPatients,
          invitedStaffs: event.invitedStaff,
          ownerID: event.ownerID,
          title: event.type,
          start: event.startTime,
          end: event.endTime,
          note: event.note,
          appointment: AppointmentTypeDisplayNames[event.type],
          telehealth: event.telehealth,
          editable: true,
          loading: event.loading ?? false,
          classNames: [colorClass, 'calendar-event'],
          currentColor: color,
        };

        // order matters here. return early if the event is loading
        if (event.loading || event.invitedStaff.length === 0) {
          return parsedEvent;
        }

        const inviteeEventCopies = event.invitedStaff.map((invitedStaff) => {
          const color = staffColorMap[invitedStaff.id]?.color;
          return {
            ...parsedEvent,
            id: `${event.id}-${invitedStaff.id}`,
            ownerID: invitedStaff.id,
            currentColor: color,
            classNames: [
              `!bg-${color}-100`,
              `!border-${color}-300`,
              'calendar-event',
            ],
          };
        });
        return [...inviteeEventCopies];
      })
      .flat();
  };

  const formattedEvents = useMemo(() => {
    return data && staffColorMap[profile?.id as string]
      ? parseEvents(data).filter((pe) => {
          if (pe.loading) return true;
          return (
            (pe.ownerID === profile?.id && showMyCalendar) ||
            selectedTeamStaff.some((ss) => ss.id === pe.ownerID)
          );
        })
      : ([] as ReturnType<typeof parseEvents>);
  }, [data, selectedTeamStaff, showMyCalendar, staffColorMap, profile]);

  const { updateAppointment } = useUpdateAppointmentMutation({
    timeRef,
    onMutate: () => {
      setSelectedEvent(null);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          'fetchAppts',
          timeRef.current.startTime,
          timeRef.current.endTime,
        ],
      });
    },
  });

  const { deleteAppointment } = useDeleteAppointmentMutation({
    timeRef,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          'fetchAppts',
          timeRef.current.startTime,
          timeRef.current.endTime,
        ],
      });
    },
  });

  const handleCreateAppt = (date: Dayjs | null) => {
    if (!date) return;
    if (selectedEvent) {
      setSelectedEvent(null);
      return;
    }
    // using the current time, set the start time on the next 30 minutes of the hour
    const currentHour = dayjs().hour();
    const nextWindow = dayjs().minute() < 30 ? 30 : 60;
    const start = date.set('hour', currentHour).set('minute', nextWindow);
    const end = start.add(30, 'minute');

    setNewEvent({
      start,
      end,
    });
    setOpenCreateModal(true);
  };

  const handleNav = (date: Dayjs) => {
    calendarAPI?.gotoDate(date.toDate());
  };

  const handleEventRemove = () => {
    if (selectedEvent) {
      selectedEvent?.event.remove();
      const eventId = selectedEvent?.event._def.extendedProps.originalId;
      deleteAppointment(eventId);
      logAnalyticEvent('calendar', 'delete_appt');
      setOpenDelete(false);
      setSelectedEvent(null);
    }
  };

  const createEventHandler = (
    info: Partial<EventImpl> & Record<string, any>,
    encounterData?:
      | {
          patientID: string;
          templateType: TemplateType;
        }
      | undefined
  ) => {
    const newAppt = {
      loading: true,
      invitedPatientIDs: info.invitedPatientIDs ?? [],
      invitedStaffIDs: info.invitedStaffIDs ?? [],
      type: info.type,
      startTime: (info.start as Date).toISOString(),
      endTime: (info.end as Date)?.toISOString(),
      telehealth: false,
      note: info.note,
      currentColor: info.currentColor,
      encounterData,
    };
    createAppointment(newAppt);
    setOpenCreateModal(false);
    setNewEvent(null);
    setSelectedEvent(null);
  };

  const editEventHandler = (
    info: Partial<EventImpl> & Record<string, any>,
    typeEvent: string
  ) => {
    const publicId = info?._def?.extendedProps.originalId || info.apptId || '';
    const editedEventForCalendar: EventInput = {
      id: publicId,
      invitedPatientIDs:
        typeEvent === 'drag'
          ? info?._def?.extendedProps?.invitedPatients?.map(
              (p: InviteeDTO) => p.id
            )
          : info.invitedPatientIDs,
      invitedStaffIDs:
        typeEvent === 'drag'
          ? info?._def?.extendedProps?.invitedStaffs?.map(
              (s: InviteeDTO) => s.id
            )
          : info.invitedStaffIDs,
      start: typeEvent === 'drag' ? info?.startStr : info.start || undefined,
      end: typeEvent === 'drag' ? info?.endStr : info.end || undefined,
      title:
        typeEvent === 'drag' ? info?._def?.title : info.dispAppt || info.appt,
      telehealth:
        typeEvent === 'drag'
          ? info?._def?.extendedProps?.telehealth
          : info.checked,
      appointment:
        typeEvent === 'drag'
          ? info?._def?.extendedProps?.appointment
          : info.dispAppt || info?._def?.extendedProps?.appointment,
      note: typeEvent === 'drag' ? info?._def?.extendedProps?.note : info.note,
      ownerID:
        typeEvent === 'drag' ? info?._def?.extendedProps.ownerID : info.ownerID,
    };

    const type = getAppointmentType(
      info.dispAppt || info?._def?.extendedProps?.appointment
    );

    const newAppt:
      | CreateAppointmentDTO
      | (UpdateAppointmentDTO & { loading: boolean; currentColor: string }) = {
      invitedPatientIDs: editedEventForCalendar.invitedPatientIDs ?? [],
      invitedStaffIDs: editedEventForCalendar.invitedStaffIDs ?? [],
      loading: true,
      type,
      startTime: (info.start as Date).toISOString(),
      endTime: (info.end as Date)?.toISOString(),
      telehealth: false,
      note: info.note,
      currentColor: info.currentColor,
    };

    if (typeEvent === 'edit') {
      updateAppointment({
        ...(newAppt as UpdateAppointmentDTO & { loading: boolean }),
        id: info.apptId as string,
      });
    }

    if (typeEvent === 'drag') {
      const dragInfo = {
        id: publicId,
        invitedPatientIDs: info?._def?.extendedProps?.invitedPatients?.map(
          (p: InviteeDTO) => p.id
        ),
        invitedStaffIDs: info?._def?.extendedProps?.invitedStaffs?.map(
          (s: InviteeDTO) => s.id
        ),
        startTime: info?.startStr,
        endTime: info?.endStr,
        telehealth: info?._def?.extendedProps?.telehealth,
        type,
        note: info?._def?.extendedProps?.note,
        currentColor: info?._def?.extendedProps?.currentColor,
      };
      updateAppointment(
        dragInfo as UpdateAppointmentDTO & {
          loading: boolean;
          currentColor: string;
        }
      );
    }
    calendarAPI?.addEvent(editedEventForCalendar);
    setOpenCreateModal(false);
  };

  const handleEventClick = (eventClickArg: EventClickArg) => {
    setSelectedEvent(eventClickArg);
  };

  const handleDates = async (rangeInfo: DatesSetArg) => {
    setCurrentDate(rangeInfo);
    timeRef.current.startTime = rangeInfo.startStr;
    timeRef.current.endTime = rangeInfo.endStr;
    await refetch();
  };

  const selectedCalendar = (e: DateSelectArg) => {
    if (selectedEvent) {
      setSelectedEvent(null);
      return;
    }
    // if the selected day is 'all day', such as in the month/year view, set the start time to 7am and end time to 7pm
    // otherwise, set the start time to the 7am and end time to 30 minutes later
    const [start, end] = e.allDay
      ? [dayjs(e.start).set('hour', 7), dayjs(e.end).subtract(5, 'hours')]
      : [dayjs(e.startStr), dayjs(e.endStr)];
    setNewEvent({ start, end });
    setOpenCreateModal(true);
  };

  const renderHeader = (param: DayHeaderContentArg) => {
    let text = '';
    let number = '';

    switch (param.view.type as CalendarViewTypes) {
      case 'timeGridDay':
      case 'timeGridWeek':
      case 'listMonth':
      case 'timeGridFiveDay':
        number = param.date.getDate().toString();
        text = param.text.slice(0, 3);
        break;
      case 'dayGridMonth':
      case 'multiMonthYear':
        text = param.text;
        break;
    }

    return (
      <div className="flex items-center justify-center">
        <Typography
          variant={'h6'}
          customClass={`${
            param.isToday ? 'text-asterGreen-900' : 'text-gray-500'
          }`}
          text={text}
        />
        <Typography
          variant={'h4'}
          customClass={`${
            param.isToday ? 'text-white bg-asterGreen-900' : 'text-black'
          }
          ${
            param.view.type === 'multiMonthYear' ||
            param.view.type === 'dayGridMonth'
              ? ''
              : 'h-[40px] w-[40px]'
          }
          ml-2 mt-0 mb-0 rounded-full flex items-center justify-center`}
          text={number}
        />
      </div>
    );
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    const viewType = calendarAPI?.view.type as CalendarViewTypes;
    const invitedPatients = eventInfo.event._def.extendedProps.invitedPatients;
    let patientInfo: string | null = null;

    if (invitedPatients?.length > 0) {
      const firstPatient = invitedPatients[0];
      const firstPatientName = `${firstPatient.firstName} ${firstPatient.lastName}`;
      const extraPatients = invitedPatients.length - 1;
      patientInfo = `${firstPatientName} ${
        extraPatients > 0 ? `+${extraPatients}` : ''
      }`;
    }

    const duration = dayjs(eventInfo.event.end).diff(
      dayjs(eventInfo.event.start),
      'minutes'
    );

    return (
      <div
        style={{
          backgroundColor:
            calendarRef.current !== null &&
            viewType !== 'timeGridDay' &&
            viewType !== 'timeGridWeek'
              ? eventInfo.backgroundColor
              : '',
          width: '100%',
          height: '100%',
          margin: 0,
          borderRadius: 4,
          textOverflow: 'ellipsis',
        }}
        className={`flex flex-row w-full`}
      >
        <div className="flex flex-col w-full overflow-hidden px-2">
          <div
            className={`flex ${
              duration >= 45 ? 'flex-col' : 'justify-between'
            } flex-wrap w-full content-start gap-x-2`}
          >
            {eventInfo?.event._def.extendedProps.loading && (
              <CircularProgress className="max-w-[10px] max-h-[10px] mt-1 mr-2" />
            )}

            {patientInfo && (
              <Typography
                variant={'bodySmall'}
                customClass="!text-gray-500 h-fit font-semibold"
                text={patientInfo}
              />
            )}
            <Typography
              variant={'bodySmall'}
              customClass="!text-gray-500 h-fit"
              text={eventInfo?.event.extendedProps.appointment}
            />
          </div>
          <div className="flex flex-wrap content-start gap-x-2">
            {calendarRef.current !== null &&
              !(calendarAPI?.view.type === 'dayGridMonth') && (
                <Typography
                  variant={'bodySmall'}
                  customClass="!text-gray-500 h-fit"
                  text={eventInfo?.timeText}
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );

  return (
    <div className="flex flex-col-reverse md:flex-row w-full md:ml-5 overflow-x-hidden overflow-y-auto flex-1">
      <div className="mt-5 overflow-auto shrink-0">
        {currentDate && (
          <CalendarSidebar
            currentDate={dayjs(currentDate.start)}
            handleNav={handleNav}
            events={formattedEvents}
            selectedTeamStaff={selectedTeamStaff}
            allTeamStaff={allTeamStaff}
            updateSelectedTeamStaff={updateSelectedTeamStaff}
            showMyCalendar={showMyCalendar}
            toggleMyCalendar={toggleMyCalendar}
            staffColorMap={staffColorMap}
            handleCreateAppt={handleCreateAppt}
          />
        )}
      </div>
      <Divider
        className="bg-grayLight"
        orientation="vertical"
        flexItem
        sx={{ margin: 1 }}
      />
      <div className="h-full w-full ml-1 mr-10 [&_th[role='presentation']]:border-none [&_div.fc-scroller-harness]:-mb-px [&_th[role='columnheader']]:border-[#cdd1de]">
        <CalendarHeader calendarRef={calendarRef} currentDate={currentDate} />
        <CalendarWrapper>
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              resourceTimelinePlugin,
              multiMonthPlugin,
              listPlugin,
            ]}
            eventTextColor={colors.gray}
            initialView="timeGridFiveDay"
            nowIndicator
            slotDuration={slotDuration}
            slotLabelInterval={slotLabelInterval}
            slotMinTime={slotMinTime}
            slotMaxTime={slotMaxTime}
            initialDate={new Date()}
            views={{
              timeGridFiveDay: {
                type: 'timeGrid',
                duration: { days: 5 },
              },
            }}
            ref={calendarRef}
            events={data ? (formattedEvents as EventSourceInput) : []}
            headerToolbar={false}
            editable
            selectable
            selectMirror
            expandRows
            allDaySlot={false}
            weekends
            dayMaxEvents
            dayCellClassNames={
              calendarRef.current !== null &&
              calendarAPI?.view.type === 'multiMonthYear'
                ? 'cell-year'
                : 'cell'
            }
            dayHeaderContent={renderHeader}
            eventContent={renderEventContent}
            eventResize={(e) => editEventHandler(e.event, 'drag')}
            datesSet={handleDates}
            eventDrop={(e) => editEventHandler(e.event, 'drag')}
            select={(e) => {
              selectedCalendar(e);
            }}
            eventClick={(e) =>
              e.event._def.publicId === 'undefined' ||
              e.event._def.extendedProps.loading
                ? null
                : handleEventClick(e)
            }
          />
        </CalendarWrapper>
      </div>

      {/* Modals */}
      {newEvent && (
        <CreateApptModal
          open={openCreateModal}
          eventInfo={newEvent}
          handleConfirm={(e, encounterData) => {
            createEventHandler(e, encounterData);
          }}
          handleClose={() => {
            setOpenCreateModal(false);
          }}
          staffColorMap={staffColorMap}
        />
      )}
      {selectedEvent && (
        <EditApptModal
          open={openEditModal && !!selectedEvent}
          eventInfo={selectedEvent.event}
          handleConfirm={(e) => {
            editEventHandler(e, 'edit');
            setOpenEditModal(false);
            logAnalyticEvent('calendar', 'edit_appt');
          }}
          handleCancel={() => {
            setOpenEditModal(false);
            setOpenDelete(true);
          }}
          handleClose={() => {
            setOpenEditModal(false);
          }}
          handleRemove={handleEventRemove}
          staffColorMap={staffColorMap}
        />
      )}
      <ConfirmationModal
        open={openDel}
        title="Delete this appointment?"
        description="This will send a cancellation notification."
        confirm="OK"
        dismiss="Cancel"
        handleClose={() => setOpenDelete(false)}
        handleConfirm={handleEventRemove}
        handleCancel={() => setOpenDelete(false)}
      />
      {selectedEvent && (
        <CalendarEventDetails
          handleEdit={() => setOpenEditModal(true)}
          handleDelete={() => setOpenDelete(true)}
          eventClickArg={selectedEvent}
          popperProps={{
            open: !!selectedEvent && selectedEvent.el.isConnected,
            anchorEl: selectedEvent?.el,
            placement: 'left',
          }}
        />
      )}
    </div>
  );
};

export default CalendarApp;
