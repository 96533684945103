import axios from '../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';
import { StaffListItemDTO } from '@aster/app/core/shared/dtos/staff';

export function useQueryStaffByEmail(email: string) {
  const fetchStaffByEmail = async () => {
    const response = await axios.get(`staffs/byEmail/${email}`);
    return response.data as StaffListItemDTO;
  };

  const { data: staff, isLoading: areStaffLoading } = useQuery({
    queryKey: ['staff', email],
    queryFn: fetchStaffByEmail,
    enabled: !!email,
  });

  return { staff, areStaffLoading };
}
