import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { useUpdatePatientMutation } from '../mutations/update-patient.mutation';
import CircularProgress from '@mui/material/CircularProgress';
import { usePatientForLabs } from '../queries/query-patient-for-labs';
import BasicTextfield from '../../../components/Textfield';
import axios from '../../../app/axiosConfig';
import { z } from 'zod';
import Typography from '../../../components/Typography';
import ButtonType from '../../../components/Button';
import SelectDropdown from '../../../components/SelectDropdown';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { US_STATES } from '@aster/app/core/shared/dtos/constants';
import FormHelperText from '@mui/material/FormHelperText';
import { useEffect } from 'react';
import { isAxiosError } from 'axios';
import { PhoneNumberField } from '../../../components/PhoneNumberField';

export default function ({ patientID }: { patientID: string }) {
  const { updatePatientMutation } = useUpdatePatientMutation(patientID);
  const { patientForLabs, isPatientLoading } = usePatientForLabs(patientID);

  const form = useForm({
    defaultValues: {
      id: patientID,
      firstName: patientForLabs?.firstName ?? '',
      lastName: patientForLabs?.lastName ?? '',
      email: patientForLabs?.email ?? '',
      dateOfBirth: patientForLabs?.dateOfBirth ?? dayjs().toISOString(),
      legalSex: patientForLabs?.legalSex,
      phoneNumber: patientForLabs?.phoneNumber ?? '',
      streetAddress1: patientForLabs?.streetAddress1 ?? '',
      city: patientForLabs?.city ?? '',
      state: patientForLabs?.state ?? '',
      zipCode: patientForLabs?.zipCode ?? '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      await updatePatientMutation.mutateAsync(value);
    },
  });

  useEffect(() => {
    if (!patientForLabs) return;
    // FIXME: this is a workaround to set the form values after the patientForLabs is loaded
    // The form values should be set in the form defaultValues, removing the useEffect should not affect the form values
    // We are researching why this is happening. In some experiemnts it works, but here in particular it does not.
    form.setFieldValue('id', patientID);
    form.setFieldValue('firstName', patientForLabs?.firstName ?? '');
    form.setFieldValue('lastName', patientForLabs?.lastName ?? '');
    form.setFieldValue('email', patientForLabs?.email ?? '');
    form.setFieldValue(
      'dateOfBirth',
      patientForLabs?.dateOfBirth ?? dayjs().toISOString()
    );
    form.setFieldValue('legalSex', patientForLabs?.legalSex ?? null);
    form.setFieldValue('phoneNumber', patientForLabs?.phoneNumber ?? '');
    form.setFieldValue('streetAddress1', patientForLabs?.streetAddress1 ?? '');
    form.setFieldValue('city', patientForLabs?.city ?? '');
    form.setFieldValue('state', patientForLabs?.state ?? '');
    form.setFieldValue('zipCode', patientForLabs?.zipCode ?? '');
  }, [form, patientForLabs, patientID]);

  if (isPatientLoading) {
    return (
      <div className="grid place-content-center flex-1">
        <CircularProgress />
      </div>
    );
  }

  return (
    <form
      className="flex flex-col items-center p-5 pt-0 max-w-[460px] rounded-[20px] mx-auto mb-8"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
    >
      <Typography
        text="Patient details must be updated before ordering labs"
        variant="body"
        customClass="text-asterGray"
      />
      <div className="grid grid-cols-2 gap-2 w-full mb-5 mt-10">
        <form.Field
          name="firstName"
          validators={{ onBlur: z.string().min(1) }}
          children={(field) => (
            <BasicTextfield
              id={field.name}
              variant="filled"
              label="First Name"
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.handleChange(event.target.value)
              }
              error={field.state.meta.errors?.length > 0}
              helperText={field.state.meta.errors?.join('\r')}
              width="100%"
            />
          )}
        ></form.Field>
        <form.Field
          name="lastName"
          validators={{ onBlur: z.string().min(1) }}
          children={(field) => (
            <BasicTextfield
              id={field.name}
              variant="filled"
              label="Last Name"
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.handleChange(event.target.value)
              }
              error={field.state.meta.errors?.length > 0}
              helperText={field.state.meta.errors?.join('\r')}
              width="100%"
            />
          )}
        ></form.Field>
      </div>
      <form.Field
        name="email"
        validators={{
          onBlur: z.string().email(),
        }}
        children={(field) => (
          <BasicTextfield
            id={field.name}
            variant="filled"
            label="Email"
            name={field.name}
            value={field.state.value}
            onBlur={field.handleBlur}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              field.handleChange(event.target.value)
            }
            width="100%"
            classes="mb-5"
            error={field.state.meta.errors?.length > 0}
            helperText={field.state.meta.errors?.join('\r')}
          />
        )}
      ></form.Field>

      <form.Field
        name="phoneNumber"
        validators={{
          onChange: z
            .string()
            .length(17, { message: 'Please provide a valid US phone number' }),
          onChangeAsyncDebounceMs: 300,
          onChangeAsync: async ({ value }) => {
            if (!value) {
              return undefined;
            }
            try {
              await axios.post('/practices/validate-phone-number', {
                phoneNumber: value,
              });
            } catch (err) {
              if (isAxiosError(err)) {
                return 'Please provide an active US phone number';
              } else {
                throw err;
              }
            }
          },
        }}
        children={(field) => (
          <PhoneNumberField
            id={field.name}
            variant="filled"
            label="Phone"
            name={field.name}
            value={field.state.value}
            onBlur={field.handleBlur}
            placeholder="+1 (xxx) xxx-xxxx"
            error={field.state.meta.errors?.length > 0}
            helperText={field.state.meta.errors?.join('\r')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              field.handleChange(event.target.value)
            }
            width="100%"
            classes={`mt-6`}
          />
        )}
      ></form.Field>
      <div className="grid grid-cols-2 gap-2 w-full mb-5 mt-10">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <form.Field
            name="dateOfBirth"
            defaultMeta={{ isTouched: true }}
            validators={{
              onChange: z.coerce
                .date()
                .max(
                  dayjs().subtract(18, 'years').toDate(),
                  'The patient must be at least 18 years old'
                ),
            }}
            children={(field) => (
              <div>
                <DateField
                  name={field.name}
                  label="Date of birth"
                  value={dayjs(field.state.value)}
                  minDate={dayjs().subtract(100, 'year')}
                  maxDate={dayjs().subtract(18, 'year')}
                  disableFuture
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: 1,
                  }}
                  onBlur={field.handleBlur}
                  onChange={(value) => {
                    field.handleChange(
                      value?.toISOString() ?? dayjs().toISOString()
                    );
                  }}
                />
                <FormHelperText error={true}>
                  {field.state.meta.errors?.join('\r')}
                </FormHelperText>
              </div>
            )}
          ></form.Field>
        </LocalizationProvider>
        <form.Field
          name="legalSex"
          validators={{ onChange: z.enum(['female', 'male', 'other']) }}
          children={(field) => (
            <SelectDropdown
              label="Legal sex"
              options={[
                { value: 'female', text: 'Female' },
                { value: 'male', text: 'Male' },
                { value: 'other', text: 'Other' },
              ]}
              value={field.state.value}
              name={field.name}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.handleChange(
                  event.target.value as 'female' | 'male' | 'other'
                )
              }
            />
          )}
        ></form.Field>
      </div>
      <div className="grid grid-cols-2 gap-2 w-full mb-5">
        <form.Field
          name="streetAddress1"
          validators={{ onBlur: z.string().min(1) }}
          children={(field) => (
            <BasicTextfield
              id={field.name}
              variant="filled"
              label="Street Address"
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.handleChange(event.target.value)
              }
              error={field.state.meta.errors?.length > 0}
              helperText={field.state.meta.errors?.join('\r')}
              width="100%"
            />
          )}
        ></form.Field>
        <form.Field
          name="city"
          validators={{ onBlur: z.string().min(1) }}
          children={(field) => (
            <BasicTextfield
              id={field.name}
              variant="filled"
              label="City"
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.handleChange(event.target.value)
              }
              error={field.state.meta.errors?.length > 0}
              helperText={field.state.meta.errors?.join('\r')}
              width="100%"
            />
          )}
        ></form.Field>
      </div>
      <div className="grid grid-cols-2 gap-2 w-full mb-5">
        <form.Field
          name="state"
          validators={{ onChange: z.string().length(2) }}
          children={(field) => (
            <SelectDropdown
              handleChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                field.handleChange(event.target.value)
              }
              options={US_STATES}
              name={field.name}
              value={field.state.value}
              required
              error={field.state.meta.errors?.length > 0}
              helperText={field.state.meta.errors?.join('\r')}
              label="State"
            />
          )}
        ></form.Field>
        <form.Field
          name="zipCode"
          validators={{ onBlur: z.string().min(1) }}
          children={(field) => (
            <BasicTextfield
              id={field.name}
              variant="filled"
              label="Zip Code"
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                field.handleChange(event.target.value)
              }
              error={field.state.meta.errors?.length > 0}
              helperText={field.state.meta.errors?.join('\r')}
              width="100%"
            />
          )}
        ></form.Field>
      </div>

      {updatePatientMutation.isError ? (
        <p className="text-red-500 mx-4 mt-4 text-bodySmall">
          {(updatePatientMutation.error as any).message}
        </p>
      ) : null}

      <form.Subscribe
        selector={(state) => [
          state.canSubmit,
          state.isSubmitting,
          state.isFieldsValidating,
          state.isValid,
        ]}
        children={([canSubmit, isSubmitting, isValidating, isValid]) => (
          <div className="flex flex-col w-full">
            <ButtonType
              className="w-full mt-10 mb-4"
              variant="contained"
              type="submit"
              text="Continue"
              disabled={!canSubmit}
              loading={isSubmitting || isValidating}
            />
            {!isValid && (
              <Typography
                customClass="text-red-500"
                text="Please correct the outstanding errors before continuing."
                variant="bodyMedium"
              />
            )}
          </div>
        )}
      />
    </form>
  );
}
