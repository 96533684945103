import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tag from '../../../components/Tag';
import { GTPALDTO, PatientProblem } from '@aster/app/core/shared/dtos/patient';
import { useNavigate, useParams } from 'react-router-dom';
import CircleWithInitials from '../../../components/CircleWIthInitials';
import GPTAL from './GTPAL';

type PatientProfileForEncounterHeaderProps = {
  age?: number | null;
  name: string;
  gtpal: GTPALDTO;
  dateOfBirth?: string | null;
  mrn?: string | null;
  problemList?: PatientProblem[];
  calculatedDueDate?: string | null;
  gestationalAge?: string | null;
  isPregnant?: boolean | null;
};

const PatientProfileForEncounterHeader = ({
  age,
  name,
  gtpal,
  dateOfBirth,
  mrn,
  problemList,
  calculatedDueDate,
  gestationalAge,
  isPregnant,
}: PatientProfileForEncounterHeaderProps) => {
  const { encounterId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="flex gap-2 m-2 mt-3 mb-4 mr-0">
      <IconButton
        className="pr-0 h-[50px] p-0"
        aria-label="Go back"
        onClick={() => (encounterId ? navigate(-1) : navigate('/patients'))}
        disableRipple
      >
        <ArrowBackIosIcon className="text-[22px] text-gray-500" />
      </IconButton>

      <CircleWithInitials
        customClass="mx-0"
        height={50}
        width={50}
        name={name}
      />

      <div className="flex flex-1 flex-col">
        <div className="flex flex-col gap-[5px]">
          <div className="flex items-center gap-[5px]">
            <p className="text-h4 font-semibold leading-6">{name}</p>
            {age && (
              <Tag
                tooltip="Age"
                className="font-normal"
                tagText={age.toString()}
              />
            )}
          </div>
          <GPTAL
            mrn={mrn}
            calculatedDueDate={calculatedDueDate}
            gestationalAge={gestationalAge}
            gtpal={gtpal}
            dateOfBirth={dateOfBirth}
            problemList={problemList}
            isPregnant={isPregnant}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientProfileForEncounterHeader;
