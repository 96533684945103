import { useMemo } from 'react';

import { InviteeDTO } from '@aster/app/core/shared/dtos/appointment';

import { useGetStaffs } from '../queries/use-get-staffs.query';

import { useAuth } from '../../../authentication/AuthProvider';
import { apptOwnerColors, colorPerInitial } from '../../../utils';

export const useStaffColorMap = () => {
  const { profile } = useAuth();
  const { staffMembers } = useGetStaffs();

  const profileColor = colorPerInitial(
    (profile?.firstName as string)[0].toUpperCase()
  );

  const staffColorMap = useMemo(() => {
    if (!staffMembers) {
      return {};
    }
    const colorMap = {
      [profile?.id as string]: profileColor,
    };
    const filteredApptOwnerColors = apptOwnerColors.filter(
      (c) => c !== profileColor
    );
    return staffMembers?.reduce(
      (acc: Record<string, InviteeDTO & { color: string }>, staff) => {
        colorMap[staff.id] =
          colorMap[staff.id] ??
          filteredApptOwnerColors[Object.keys(colorMap).length % 7];
        return {
          ...acc,
          [staff.id]: {
            id: staff.id,
            email: staff.email,
            firstName: staff.firstName,
            lastName: staff.lastName,
            color: colorMap[staff.id],
          },
        };
      },
      {}
    );
  }, [staffMembers, profile?.id, profileColor]);

  return { staffColorMap, profileColor };
};
