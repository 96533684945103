import axios from '../../../app/axiosConfig';
import { PatientForLabsDTO } from '@aster/app/core/shared/dtos/patient';
import { useQuery } from '@tanstack/react-query';

export function usePatientForLabs(patientID: string | undefined) {
  const fetchPatientForLabs = async () => {
    const response = await axios.get<PatientForLabsDTO>(
      `/v2/patients/for-labs/${patientID}`
    );
    return response.data;
  };

  const { data: patientForLabs, isLoading: isPatientLoading } = useQuery({
    queryKey: ['patientForLabs', patientID],
    queryFn: fetchPatientForLabs,

    enabled: patientID !== undefined,
  });

  return { patientForLabs, isPatientLoading };
}
