import { StaffListItemDTO } from '@aster/app/core/shared/dtos/staff';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const fetchStaff = async () => {
  const response = await axios.get<StaffListItemDTO[]>(`staffs`);
  return response.data;
};

export const useGetStaffs = () => {
  const { data: staffMembers, isLoading: areStaffLoading } = useQuery({
    queryKey: ['staffs'],
    queryFn: () => fetchStaff(),
  });

  return { staffMembers, areStaffLoading };
};
