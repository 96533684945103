import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

type Placement = 'start' | 'top' | 'end' | 'bottom';
type CheckProps = CheckboxProps & {
  label?: string;
  placement?: Placement;
  disabled?: boolean;
  defaultChecked?: boolean;
  classes?: string;
};

const BasicCheckbox = (props: CheckProps) => {
  const {
    label,
    disabled,
    defaultChecked,
    placement,
    classes,
    ...checkboxProps
  } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox {...checkboxProps} className={classes} disableRipple />
      }
      label={label}
      classes={{ label: 'text-bodyMedium' }}
      disabled={disabled}
      key={label}
      defaultChecked={defaultChecked}
      labelPlacement={placement || 'end'}
    />
  );
};

export default BasicCheckbox;
