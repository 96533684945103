import { EFaxSearchArgsDTO, FaxListItemDTO } from '@aster/app/core/shared/dtos/efax';
import { PaginatedResult, searchArgsToString } from '@aster/app/core/shared/dtos/search';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';

const fetchFaxes = async (searchArgs: EFaxSearchArgsDTO = {}) => {
  const response = await axios.get<PaginatedResult<FaxListItemDTO>>(
    `/v2/efax/search?${searchArgsToString(searchArgs)}`
  );
  return response.data;
};

export function useSearchFaxesQuery(
  searchArgs: EFaxSearchArgsDTO = {
    search: '',
    page: 1,
    pageSize: 10,
    isSendDirection: false,
  },
  isSetup: boolean
) {
  const {
    data: paginatedFaxes,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      'faxes',
      searchArgs.search,
      searchArgs.page,
      searchArgs.pageSize,
      searchArgs.sortBy,
      searchArgs.sortDir,
      searchArgs.isSendDirection,
    ],
    queryFn: () => fetchFaxes(searchArgs),
    enabled: isSetup,
    placeholderData: keepPreviousData,
  });

  return {
    paginatedFaxes,
    areFaxesLoading: isLoading,
    areFaxesFetching: isFetching,
  };
}
