import { useQuery } from '@tanstack/react-query';
import axios from '../app/axiosConfig';
import { PracticeDTO } from '@aster/app/core/shared/dtos/practice';

const fetchPractice = async (practiceID: string) => {
  const response = await axios.get<PracticeDTO>(`/v2/practices/${practiceID}`);
  return response.data;
};

export const usePracticeDetailsQuery = (practiceID?: string) => {
  const { data, isLoading, ...rest } = useQuery({
    queryKey: ['practices', practiceID],
    queryFn: () => fetchPractice(practiceID as string),
    enabled: !!practiceID,
  });
  return {
    practiceInfo: data,
    isPracticeLoading: isLoading,
    ...rest,
  };
};
