import { useCallback, useEffect, useMemo, useState } from 'react';
import { PatientProfileForEncounterDTO } from '@aster/app/core/shared/dtos/patient';
import ButtonType from '../../../components/Button';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { colors } from '../../../theme';
import { useAuth } from '../../../authentication/AuthProvider';
import { NoteInfo } from './types/birth.types';
import TemplateComponent from '../templates/TemplateComponent';
import { BirthNotes } from './types/notes.types';
import { useParams } from 'react-router-dom';
import { encounterNoteKey } from './constants/notes.contants';

type NoteProps = {
  type: BirthNotes;
  data: NoteInfo[];
  patientData: PatientProfileForEncounterDTO | undefined;
  disabled?: boolean;
  patient: string;
  nameNote: string;
  handleSave: (property: string, event: NoteInfo[]) => void;
  enforceSaveMissing: boolean;
  handleUnsaved: (isDirty: boolean) => void;
  useLargeNotes?: boolean;
};

const emptyNote = ['', '<p><br></p>', '<p></p>', '<p> </p>'];

const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  note: {
    marginBottom: 10,
  },
  title: {
    fontSize: 15,
    marginBottom: 10,
    marginTop: 5,
  },
  info: {
    fontSize: 10,
    color: '#203A3B',
  },
  textDate: {
    color: '#D3D3D3',
    fontSize: 8,
  },
  textContent: {
    color: '#586068',
    fontSize: 10,
  },
});

const buttons = [
  { value: 'history', text: 'History' },
  { value: 'labs', text: 'Labs/Ultrasounds' },
];

const Notes = ({
  type,
  data,
  disabled,
  patient,
  nameNote,
  handleSave,
  handleUnsaved,
  enforceSaveMissing,
  useLargeNotes = true,
}: NoteProps) => {
  const [note, setNote] = useState<string>('');
  const notes = useMemo(() => data || [], [data]);
  const { encounterId } = useParams();

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const { profile } = useAuth();

  const getTimestampAuthor = useCallback((): string => {
    const currentDateTime = new Date();
    let displayInfo = currentDateTime.toLocaleString();
    if (profile)
      displayInfo = `${currentDateTime.toLocaleString()}, ${
        profile.firstName
      } ${profile.lastName}`;
    return displayInfo;
  }, [profile]);

  const handleChangeNote = (content: string) => {
    setNote(content);
    localStorage.setItem(
      encounterNoteKey(encounterId as string, type),
      content
    );
  };

  const removeHTMLTags = (inputString: string) => {
    return inputString.replace(/<[^>]*>/g, '');
  };

  useEffect(() => {
    const unsavedNote = localStorage.getItem(
      encounterNoteKey(encounterId as string, type)
    );
    if (unsavedNote) {
      setNote(unsavedNote);
    }
  }, [encounterId, type]);

  const handleSaveNote = useCallback(() => {
    if (!emptyNote.includes(note.trim())) {
      const newNote = {
        content: removeHTMLTags(note),
        timestamp: getTimestampAuthor(),
      };
      if (editIndex !== null) {
        const updatedNotes = [...notes];
        updatedNotes[editIndex] = newNote;
        handleSave(nameNote, updatedNotes);
        setEditIndex(null);
      } else {
        handleSave(nameNote, [newNote, ...notes]);
      }
      localStorage.removeItem(encounterNoteKey(encounterId as string, type));
      handleUnsaved(true);
      setNote('');
    }
  }, [
    editIndex,
    encounterId,
    getTimestampAuthor,
    handleSave,
    handleUnsaved,
    nameNote,
    note,
    notes,
    type,
  ]);

  useEffect(() => {
    if (enforceSaveMissing && !emptyNote.includes(note.trim())) {
      handleSaveNote();
    }
  }, [enforceSaveMissing, handleSaveNote, note, patient]);

  const handleEditNote = (index: number) => {
    setNote(notes[index].content);
    setEditIndex(index);
  };

  const handleDeleteNote = (index: number) => {
    const updatedNotes = [...notes];
    updatedNotes.splice(index, 1);
    handleSave(nameNote, updatedNotes);
  };

  const ExportPDF = () => {
    return (
      <Document>
        <Page size="A4">
          <View style={styles.container}>
            {profile && (
              <>
                <Text
                  style={styles.info}
                >{`Provider: ${profile.firstName} ${profile.lastName}`}</Text>
                <Text
                  style={styles.info}
                >{`Clinic: ${profile.practiceName}`}</Text>
                <Text style={styles.info}>{`Patient: ${patient}`}</Text>
              </>
            )}
            <Text style={styles.title}>Labor & birth Note</Text>

            {notes.map((note, index) => (
              <View key={index} style={styles.note}>
                <Text style={styles.textDate}> {note.timestamp}</Text>
                <Text style={styles.textContent}>{note.content}</Text>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <div className="w-full h-full overflow-auto ">
      <div className="flex flex-row gap-2">
        <TemplateComponent
          noteInfo={note}
          signed={disabled ?? false}
          handleChangeNote={handleChangeNote}
          buttons={buttons}
          useLargeNotes={useLargeNotes}
          headerButtons={
            <div className="flex items-center gap-2">
              {!disabled && (
                <ButtonType
                  variant="contained"
                  disabled={disabled}
                  onClick={handleSaveNote}
                  text={editIndex !== null ? 'Update' : 'Save'}
                  classes={'max-h-[30px] mt-2'}
                />
              )}
              <PDFDownloadLink
                document={<ExportPDF />}
                fileName={`notes-${dayjs().format('MM-DD-YY')}.pdf`}
              >
                <ButtonType
                  variant="text"
                  text=""
                  classes={'max-h-[30px] mt-2'}
                  icon
                  specificIcon={
                    <DownloadIcon
                      sx={{
                        color: colors.aster,
                        cursor: 'pointer',
                      }}
                    />
                  }
                />
              </PDFDownloadLink>
            </div>
          }
        />
      </div>

      <ul>
        {notes.map((note, index) => (
          <li key={index} className="my-2">
            <div className="flex flex-row gap-2">
              <div className="text-bodySmall text-gray-500Block">
                {note.timestamp}
              </div>
              {!disabled && (
                <>
                  <button
                    onClick={() => handleEditNote(index)}
                    className="flex gap-1.5 justify-center px-5 text-sm text-center text-gray-500 whitespace-nowrap rounded-xl"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteNote(index)}
                    className="flex gap-1.5 justify-center px-5 text-sm text-center text-gray-500 whitespace-nowrap rounded-xl"
                  >
                    Delete
                  </button>
                </>
              )}
            </div>

            <div className="flex flex-row gap-2">
              <div className="w-full my-1 text-gray-500">{note.content}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Notes;
